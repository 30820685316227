<video-search-sidebar-header [title]="(isBulkGenerateMetadataEnabled && isBulkTranscribeEnabled ? 'Media_BulkEdit_TranscriptionMetadata' : isBulkTranscribeEnabled ? 'Media_BulkEdit_Transcription' : 'Media_BulkEdit_Metadata') | translate" [titleId]="'transcriptionMetadataForm'"></video-search-sidebar-header>

<form class="sidebar-form vb-scrollbar transcription-metadata-form" aria-labelledby="transcriptionMetadataForm" #form="ngForm" (submit)="submit()">

	@if (isBulkTranscribeEnabled) {
		<vb-ui-form-group [labelClass]="'transcribeField'">
			<label for="generateTranscript" #uiLabel>
				{{ 'Transcribe' | translate }} ({{ fields.generateTranscript.languageName }}):
			</label>
			<div dropdown container="body" placement="bottom left" vbDropdown>
				<button type="button" class="btn dropdown-toggle btn-transparent selectLanguage" dropdownToggle>
					<span class="util-underline">{{ 'SelectLanguage' | translate }}</span>
				</button>
				<div *dropdownMenu role="menu" class="dropdown-menu vb-scrollbar">
					@for (language of transcriptionLanguages; track language) {
						<button type="button" role="menuitem" class="btn btn-transparent dropdown-item" (click)="selectLanguage(language)">
							{{ language.name }}
						</button>
					}
				</div>
			</div>

			<select name="generateTranscript" class="form-select" [(ngModel)]="fields.generateTranscript.value" uiControl>
				@for (option of generateOptions['transcript']; track option) {
					<option [value]="option.value">
						{{ option.label }}
					</option>
				}
			</select>
		</vb-ui-form-group>
	}

	@if (isBulkGenerateMetadataEnabled) {
		<vb-ui-form-group>
			<label for="generateTitle" #uiLabel>
				{{ 'Title' | translate }}:
			</label>
			<select name="generateTitle" class="form-select" [(ngModel)]="fields.generateTitle.value" uiControl>
				@for (option of generateOptions['title']; track option) {
					<option [value]="option.value">
						{{ option.label }}
					</option>
				}
			</select>
		</vb-ui-form-group>

		<vb-ui-form-group>
			<label for="generateDescription" #uiLabel>
				{{ 'Description' | translate }}:
			</label>
			<select name="generateDescription" class="form-select" [(ngModel)]="fields.generateDescription.value" uiControl>
				@for (option of generateOptions['description']; track option) {
					<option [value]="option.value">
						{{ option.label }}
					</option>
				}
			</select>
		</vb-ui-form-group>

		<vb-ui-form-group>
			<label for="generateTags" #uiLabel>
				{{ 'Tags' | translate }}:
			</label>
			<select name="generateTags" class="form-select" [(ngModel)]="fields.generateTags.value" uiControl>
				@for (option of generateOptions['tags']; track option) {
					<option [value]="option.value">
						{{ option.label }}
					</option>
				}
			</select>
		</vb-ui-form-group>

		<vb-ui-form-group>
			<label for="generateChapters" #uiLabel>
				{{ 'Chapters' | translate }}:
			</label>
			<select name="generateChapters" class="form-select" [(ngModel)]="fields.generateChapters.value" uiControl>
				@for (option of generateOptions['chapters']; track option) {
					<option [value]="option.value">
						{{ option.label }}
					</option>
				}
			</select>
		</vb-ui-form-group>
	}

	<button type="submit" class="btn btn-transparent generateBtn" [disabled]="form.invalid || !BulkGenerateFormStateService.hasAnyOperations() || !VideoSelectionModel.selectionCountValid" [attr.aria-label]="'Generate' | translate">
		<span class="vb-icon vb-icon-center vb-icon-sparkle margin-right-5"></span>
		<span>{{ 'Generate' | translate }}</span>
	</button>
</form>