import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import styles from './DownloadDetails.module.less';

@Component({
	selector: 'download-details',
	templateUrl: './DownloadDetails.Component.html'
})
export class DownloadDetailsComponent implements OnInit {
	@Input() private accountId: string;
	@Input() private fileId: string;
	@Input() private fileDownloadUrl: string;

	public readonly styles = styles;
	public downloadUrl: string;

	public ngOnInit(): void {
		this.downloadUrl = this.fileDownloadUrl ? this.fileDownloadUrl : `/network/accounts/${this.accountId}/downloads/${this.fileId}`;
	}
}
