import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { SameSite, getCookie, setCookie } from 'rev-shared/util/CookieUtil';

const messageCookie = 'revSystemMessage';
const messageCookieExpiry = 7 * 24 * 3600000;

@Injectable({
	providedIn: 'root'
})
export class SystemMessageService {

	constructor(
		private http: HttpClient
	) {
	}

	public loadUserMessage(): Promise<any> {
		const cookie = getCookie(messageCookie);
		let params = new HttpParams();

		if (cookie) {
			params = params.append('since', new Date(+cookie).toISOString());
		}

		return lastValueFrom(this.http.get('/system-message', { params }))
			.then((result: any) => {
				return result?.message;
			});
	}

	public markMessageAsRead(): void {
		const expires = new Date(Date.now() + messageCookieExpiry);
		setCookie(messageCookie, Date.now().toString(), { expires, sameSite: SameSite.Strict });
	}
}
