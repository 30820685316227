<vb-title [title]="('Title_MediaSettings' | translate) + ' - ' + ('Title_Categories' | translate)">
</vb-title>

<vb-loading-spinner [block]="true" [size]="'large'" *ngIf="status.saving">
	{{ 'Processing' | translate }}
</vb-loading-spinner>

<div [ngClass]="styles.scrollbar">
	<div class="row" [hidden]="status.saving">
		<div class="col-xs-12">
			<form class="form-horizontal" #categoryForm="ngForm" (submit)="saveCategory()">
				<div class="card">
					<header class="card-header" [ngClass]="styles.panelHeading" layout="row" layout-wrap="false">
						<h4 flex="fill">{{category.name}}</h4>
						<div>
							<button type="button" class="me-2" [disabled]="!category.id" vbBtnSecondary (click)="removeCategory()">
								<span class="glyphicons bin"></span>
								{{ 'Delete' | translate }}
							</button>
							<a class="me-2" vbBtnSecondary [uiSref]="cancelSref.name" [uiParams]="cancelSref.params">
								{{ 'Cancel' | translate }}
							</a>

							<button type="submit" vbBtnPrimary [disabled]="!categoryForm.valid">
								{{ 'SaveCategory' | translate }}
							</button>
							<vb-confirmation-dialog [title]="'Delete' | translate" [message]="'Admin_Categories_Delete' | translate : { '0': category.name }" [cancelText]="'Cancel' | translate" [actionText]="'Delete' | translate">
							</vb-confirmation-dialog>
						</div>
					</header>
					<div class="card-body" [ngClass]="styles.container">
						<div [ngClass]="styles.column">
							<div [ngClass]="styles.columnTitle">
								{{ 'CategoryName' | translate }}
							</div>
							<vb-ui-form-group>
								<input type="text" [attr.aria-label]="'CategoryName' | translate" class="form-control input-with-icon" name="categoryName" #categoryName="ngModel" vbRequired autocomplete="new-password" [(ngModel)]="category.name" uiControl vbUiTextInput [vbClearOnChange]="'isCategoryNameInUse'" [vbMaxLength]="100" trim="blur"/>
								<div *ngIf="categoryName.touched && categoryName.invalid" class="margin-bottom-10" role="alert" vbUiErrorMessage uiValidation>
									<ng-container *ngIf="categoryName.errors.required">
										{{ 'ThisFieldIsRequired' | translate }}
									</ng-container>
									<ng-container *ngIf="categoryName.errors.isCategoryNameInUse">
										{{ 'CategoryNameInUse' | translate }}
									</ng-container>
									<ng-container *ngIf="categoryName.errors.vbMaxLength">
										{{ 'CategoryInvalidName' | translate }}
									</ng-container>
								</div>
							</vb-ui-form-group>
							<image-selector-v2 #imageModel="ngModel" name="categoryThumbnailImage" [label]="'CategoryThumbnail' | translate" [imageComponentMetadata]="imageComponentMetadata" [isAdminTheme]="isAdminTheme" [(ngModel)]="imageData" (ngModelChange)="onUploadThumbnailImage()">
							</image-selector-v2>
							<span>{{ 'Category_ThumbnailImageUploadNote' | translate }}</span>
						</div>
						<div [ngClass]="styles.column">
							<div [ngClass]="styles.columnTitle">{{'Admin_Roles_Permissions' | translate}}</div>

							<vb-ui-form-group>
								<vb-ui-checkbox name="restricted" [(ngModel)]="category.restricted" uiControl>
									{{ 'Admin_MediaSettings_Restricted' | translate }}

									<span [tooltip]="'Category_Restricted_Tooltip' | translate " placement="right">
										<span class="glyphicons circle_question_mark"></span>
									</span>
								</vb-ui-checkbox>
							</vb-ui-form-group>

							<ng-template #optionRow let-item="item" let-insight="insight">
								<access-entity-option-row [item]="item" [insight]="insight"></access-entity-option-row>
							</ng-template>

							<ng-template #assignedRow let-item="item" let-insight="insight">
								<category-admin-assigned-row [item]="item" [insight]="insight"></category-admin-assigned-row>
							</ng-template>

							<insight [hidden]="!category.restricted" name="accessEntities" [attr.aria-label]="'Admin_Roles_Permissions' | translate" [insightOptions]="accessControlInsightOptions" [(ngModel)]="category.categoryPolicyItems" [optionTemplate]="optionRow" [assignedTemplate]="assignedRow">
							</insight>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

