<section class="container-fluid team-container">
	<section class="team-header">
		<span class="team-heading">{{ 'Title_Teams' | translate }}</span>
		<div class="team-controls">
			<div class="team-control-items">
				<a uiSref="portal.team-edit" class="team-add-btn" *ngIf="isCreateTeamAccess" vbBtnPrimary>
					{{ 'Admin_Devices_SetTopBox_ChannelSettings_AddChannel' | translate }}
				</a>
				<span class="team-search-container">
					<span class="glyphicons search"></span>
					<input type="text" [(ngModel)]="filterTerm" (ngModelChange)="filterTeamsList()" [placeholder]="'Teams_SearchChannels' | translate" [attr.aria-label]="'Teams_SearchChannels' | translate" vbUiTextInput/>
				</span>
			</div>
		</div>
	</section>
</section>
<section class="container-fluid team-container">
	<section class="row team-wrapper">
		<div class="col-xl-2 col-lg-3 col-sm-4 col-tablet-sm-6 col-xs-12 team-inner-wrapper" *ngFor="let team of filteredTeams; trackBy: getTeamId;">
			<a class="team-box" uiSref="portal.media" [attr.aria-label]="team.name" [uiParams]="{team: team.id}">
				<div class="team-logo" [ngStyle]="{'background-color': team.bgColor}">
					<h2 class="team-name text-overflow-hide" [ngStyle]="{'color': team.themeColor}">{{team.name}}</h2>
					<div class="team-name-wrapper" [ngStyle]="team.thumbnailUri ? {'background-image': 'url(' + team.thumbnailUri + ')'} : ''"></div>
				</div>
				<footer>
					<span class="glyphicons facetime_video"></span>
					<span class="team-video-count" [attr.aria-label]="'Teams_N_Videos' | translate : { '0': team.totalVideos } ">{{team.totalVideos}}</span>
				</footer>
			</a>
		</div>
		<div class="team-not-available" *ngIf="!teams.length">
			<h3>{{ 'Teams_NotAvailable' | translate }}</h3>
		</div>
		<div class="team-no-search">
			<h3>{{ 'Teams_NoSearchResults' | translate }}</h3>
		</div>
	</section>
</section>
