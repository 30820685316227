import type { SuppressKeyboardEventParams } from '@ag-grid-community/core';

export function getGridFilterDetails(filterModel: { [key: string]: any; }): { query: string, filterFields: string[]; } {
	const filterFields: string[] = Object.keys(filterModel);
	const query = filterModel[filterFields[0]]?.filter;

	return {
		query,
		filterFields
	};
}

export function handleTabKeyPress(params: SuppressKeyboardEventParams): boolean {
	const event = params.event;
	return (event.code === 'Tab' || event.key === 'Tab') && allowTabbingIntoInnerContent(params);
}

export function allowTabbingIntoInnerContent(params: SuppressKeyboardEventParams): boolean {
	const event = params.event;
	const eventEl = (event.srcElement as HTMLElement);

	const focusableChildrenOfParent = eventEl.closest('.ag-cell')
		.querySelectorAll("a, [href], button, :not(.ag-hidden) > input, select, [tabindex]:not([tabindex='-1'])");

	return (
		focusableChildrenOfParent?.length > 0 &&
		(
			event.shiftKey
				? eventEl !== focusableChildrenOfParent[0] || eventEl.classList.contains('ag-cell')
				: eventEl !== focusableChildrenOfParent[focusableChildrenOfParent.length - 1]
		)
	);
}

export function clickAnchorChild(params: SuppressKeyboardEventParams): void {
	const event = params.event;
	const eventEl = (event.srcElement as HTMLElement);

	const anchorTagChildOfParent = eventEl.closest('.ag-cell').querySelector('a');

	anchorTagChildOfParent?.click();
}

export function isOnlyFocusableChildAnchorTag(el: HTMLElement): boolean {
	const focusableChildrenOfParent = el.closest('.ag-cell')
		.querySelectorAll("a, [href], button, :not(.ag-hidden) > input, select, [tabindex]:not([tabindex='-1'])");
	return focusableChildrenOfParent?.length === 1 && focusableChildrenOfParent[0]?.tagName === 'A';
}
