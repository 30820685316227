import { Component, Input } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { noop } from 'underscore';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { TranscriptionLanguagesService } from 'rev-shared/media/transcription/TranscriptionLanguages.Service';

import { BulkEditService } from './BulkEdit.Service';
import { BulkGenerateOperator, BulkGenerateFormStateService, IBulkGenerateForm } from './BulkGenerateFormState.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';
import { VideoSearchResultsComponent } from '../VideoSearchResults.Component';
import { ConfirmationDialogAngularComponent } from 'rev-shared/ui/dialog/ConfirmationDialogAngular.Component';
import { firstValueFrom } from 'rev-shared/rxjs/firstValueFrom';
import { VbTranslateService } from 'rev-shared/util/VbTranslate.Service';
import { getPreferredTranscribeLanguage } from 'rev-shared/videoAiMetadata/TranscribeLanguagePreference';

@Component({
	selector: 'bulk-generate-metadata',
	templateUrl: './BulkGenerateMetadata.Component.html'
})
export class BulkGenerateMetadataComponent {
	@Input() public isBulkGenerateMetadataEnabled: boolean;
	@Input() public isBulkTranscribeEnabled: boolean;

	public readonly transcriptionLanguages = this.TranscriptionLanguagesService.aiServiceLanguages;
	public readonly generateOptions = {
		transcript: [
			{ value: BulkGenerateOperator.Generate, label: this.TranslateService.instant('Media_BulkEdit_GenerateForVideosWithNoTranscript') },
			{ value: BulkGenerateOperator.Replace, label: this.TranslateService.instant('Media_BulkEdit_GenerateAndReplace') },
			{ value: BulkGenerateOperator.DoNotGenerate, label: this.TranslateService.instant('Media_BulkEdit_DoNotGenerate') }
		],
		title: [
			{ value: BulkGenerateOperator.Generate, label: this.TranslateService.instant('Media_BulkEdit_GenerateAndReplace') },
			{ value: BulkGenerateOperator.DoNotGenerate, label: this.TranslateService.instant('Media_BulkEdit_DoNotGenerate') }
		],
		description: [
			{ value: BulkGenerateOperator.Generate, label: this.TranslateService.instant('Media_BulkEdit_GenerateForEmptyFields') },
			{ value: BulkGenerateOperator.Append, label: this.TranslateService.instant('Media_BulkEdit_GenerateAndAppend') },
			{ value: BulkGenerateOperator.DoNotGenerate, label: this.TranslateService.instant('Media_BulkEdit_DoNotGenerate') },
		],
		tags: [
			{ value: BulkGenerateOperator.Generate, label: this.TranslateService.instant('Media_BulkEdit_GenerateForEmptyFields') },
			{ value: BulkGenerateOperator.Append, label: this.TranslateService.instant('Media_BulkEdit_GenerateAndAppend') },
			{ value: BulkGenerateOperator.DoNotGenerate, label: this.TranslateService.instant('Media_BulkEdit_DoNotGenerate') },
		],
		chapters: [
			{ value: BulkGenerateOperator.Generate, label: this.TranslateService.instant('Media_BulkEdit_GenerateForEmptyFields') },
			{ value: BulkGenerateOperator.Replace, label: this.TranslateService.instant('Media_BulkEdit_GenerateAndReplace') },
			{ value: BulkGenerateOperator.DoNotGenerate, label: this.TranslateService.instant('Media_BulkEdit_DoNotGenerate') },
		],
	};

	public fields: IBulkGenerateForm;

	private processingDialog: ConfirmationDialogAngularComponent;
	private processingDialogTimer: number;

	constructor(
		public VideoSelectionModel: VideoSelectionModelService,
		public BulkGenerateFormStateService: BulkGenerateFormStateService,
		private BulkEditService: BulkEditService,
		private TranslateService: TranslateService,
		private Dialog: DialogService,
		private TranscriptionLanguagesService: TranscriptionLanguagesService,
		public videoSearchResults: VideoSearchResultsComponent,
		private VbTranslate: VbTranslateService
	) { }

	public ngOnInit(): void {
		this.fields = this.BulkGenerateFormStateService.fields;

		this.configureDefaultFields();
	}

	public configureDefaultFields(): void {
		const defaultTranscribeLanguage = getPreferredTranscribeLanguage(this.transcriptionLanguages as any);
		this.fields.generateTranscript.languageId = defaultTranscribeLanguage.code;
		this.fields.generateTranscript.languageName = defaultTranscribeLanguage.name;

		if (!this.isBulkGenerateMetadataEnabled) {
			this.fields.generateTitle.value = BulkGenerateOperator.DoNotGenerate;
			this.fields.generateDescription.value = BulkGenerateOperator.DoNotGenerate;
			this.fields.generateTags.value = BulkGenerateOperator.DoNotGenerate;
			this.fields.generateChapters.value = BulkGenerateOperator.DoNotGenerate;
		}

		if (!this.isBulkTranscribeEnabled) {
			this.fields.generateTranscript.value = BulkGenerateOperator.DoNotGenerate;
		}
	}

	public selectLanguage(language: any): void {
		this.fields.generateTranscript.languageId = language.code;
		this.fields.generateTranscript.languageName = language.name;
	}

	public submit(): void {
		const confirmationMsgHtml = `<div>${this.TranslateService.instant(this.confirmationMsgKey, { 0: this.VideoSelectionModel.selectionCount })}</div>`;
		const metadataMsgHtml = this.isBulkMetadataUpdate ? `<div class="margin-top-10">${this.TranslateService.instant('Media_BulkEdit_Confirm_Metadata_Message')}</div>` : '';

		this.Dialog.openConfirmationDialog({
			title: this.TranslateService.instant(this.isBulkGenerateMetadataEnabled && this.isBulkTranscribeEnabled ? 'Media_BulkEdit_TranscriptionMetadata' : this.isBulkTranscribeEnabled ? 'Media_BulkEdit_Transcription' : 'Media_BulkEdit_Metadata'),
			htmlMessage: `
				${confirmationMsgHtml}
				${metadataMsgHtml}
			`,
			cancelText: this.TranslateService.instant('Cancel'),
			actionText: this.TranslateService.instant('Save')
		})
			.result
			.then(() => this.generateVideoMetadata(), noop);
	}

	private ensureSelectionLoaded(): Promise<any> {
		return Promise.resolve(this.VideoSelectionModel.isSelectAll && this.videoSearchResults.forceLoadAllSearchResults());
	}

	private generateVideoMetadata(): void {
		const transcription = this.BulkGenerateFormStateService.getTranscriptOperation();
		const metadataGenerationFields = this.BulkGenerateFormStateService.getMetadataGenerationOperations();

		this.ensureSelectionLoaded()
			.then(() => this.BulkEditService.generateVideoMetadata(this.VideoSelectionModel.selectedVideos.map(v => v.id), transcription, metadataGenerationFields))
			.then(({ message }) => {
				this.closeProcessingDialog();
				return firstValueFrom(
					this.Dialog.getDialog('bulkEditSubmission')
						.open({
							initialState: {
								legalHoldVideosCount: (message.legalHoldVideoIds || []).length,
								recordingVideosCount: (message.recordingVideoIds || []).length,
								isEditing: true
							}
						}).onHide
				);
			})
			.catch(e => {
				console.error('unable to submit bulk action', e);
				this.Dialog.openAlertDialog(...this.VbTranslate.translateKeys(
					['Error', 'Media_BulkEdit_ProcessingError', 'Ok']
				));
			})
			.finally(() => this.closeProcessingDialog());
	}

	private openProcessingDialog(): void {
		this.processingDialogTimer = window.setTimeout(() =>
			this.processingDialog = this.Dialog.openConfirmationDialog({
				title: this.TranslateService.instant('VideoSettings'),
				message: this.TranslateService.instant('Media_BulkEdit_Processing')
			}), 1000);
	}

	private closeProcessingDialog(): void {
		window.clearTimeout(this.processingDialogTimer);
		if (this.processingDialog) {
			this.processingDialog.hide();
		}
		this.processingDialogTimer = this.processingDialog = null;
	}

	private get confirmationMsgKey(): string {
		let confirmationMsgKey = '';
		if (this.isBulkTranscribeUpdate && this.isBulkMetadataUpdate) {
			confirmationMsgKey = 'Media_BulkEdit_Confirm_TranscriptionMetadata';
		} else if (this.isBulkTranscribeUpdate) {
			confirmationMsgKey = 'Media_BulkEdit_Confirm_Transcription';
		} else if (this.isBulkMetadataUpdate) {
			confirmationMsgKey = 'Media_BulkEdit_Confirm_Metadata';
		}
		return confirmationMsgKey;
	}

	private get isBulkMetadataUpdate(): boolean {
		return this.BulkGenerateFormStateService.getMetadataGenerationOperations().some(field => field.action !== BulkGenerateOperator.DoNotGenerate);
	}

	private get isBulkTranscribeUpdate(): boolean {
		return !!this.BulkGenerateFormStateService.getTranscriptOperation()?.action;
	}

}
