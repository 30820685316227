import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { PushService } from 'rev-shared/push/PushService';
import { UploadService } from 'rev-shared/media/Upload.Service';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { ICategory } from './CategoryContract';
import { CategoryManagerItemType, CategoryContributorItemType } from './Constants';
import { promiseFromTimeout } from '../../util/PromiseUtil';


@Injectable()
export class AdminCategoryService {

	constructor(
		private http: HttpClient,
		private PushService: PushService,
		private UploadService: UploadService,
	) {
	}
	private readonly mapPolicyItem = ({ id, type, categoryManager }: any): any => ({
		id,
		type,
		itemType: categoryManager ? CategoryManagerItemType : CategoryContributorItemType
	});

	public uploadPreviewThumbnailImage(category: ICategory): Promise<string> {
		const previewThumbnailFile: FileWrapper = category.thumbnailImageFile;
		if (!previewThumbnailFile) {
			return Promise.resolve(category.thumbnailImageId);
		}

		return (this.UploadService.uploadCategoryImage('Category', category.id, previewThumbnailFile))
			.then(result => result.message.imageId);
	}

	public createCategory(category: ICategory): Promise<void> {
		return this.PushService.dispatchCommand('media:AddCategoryToAccount', {
			accountId: category.accountId,
			name: category.name,
			parentCategoryId: category.parentCategoryId,
			restricted: category.restricted,
			categoryPolicyItems: category.categoryPolicyItems?.map(this.mapPolicyItem) || []
		}, 'CategoryCreated')
			.then((categoryResponse: any) => {
				category.id = categoryResponse.message.categoryId;
				if (category.thumbnailImageFile) {
					// Sleep for a second to allow the category to be created before uploading the thumbnail image
					return promiseFromTimeout(1000).then(() => this.saveCategory(category));
				}
			});
	}

	public getCategory(categoryId: string): Promise<any> {
		return lastValueFrom<any>(this.http.get(`/media/categories/${ categoryId }`))
			.then(category => {
				category.categoryPolicyItems = category.categorySecurityPolicy?.categoryPolicyItems
					?.map(({ id, type, itemType }) => ({
						id,
						type,
						categoryManager: itemType === CategoryManagerItemType
					})) || [];
				return category;
			});
	}

	public removeCategory(accountId: string, categoryId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:RemoveCategory', {
			accountId,
			categoryId
		});
	}

	public saveCategory(category: ICategory): Promise<void> {
		return this.uploadPreviewThumbnailImage(category).then(thumbnailId => {
			return this.PushService.dispatchCommand('media:SaveCategoryDetails', {
				accountId: category.accountId,
				categoryId: category.id,
				thumbnailImageId: thumbnailId,
				name: category.name,
				restricted: category.restricted,
				categoryPolicyItems: category.categoryPolicyItems.map(this.mapPolicyItem)
			});
		});
	}
}
