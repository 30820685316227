<a [ngClass]="styles.thumbnailAnchor" [href]="link" [attr.aria-label]="config.ariaLabel">
	<div [ngClass]="styles.thumbnailWrapper" [ngStyle]="{ height: thumbnailHeight + 'px'}">
		<div [ngClass]="styles.icon" class="glyphicons lock" [hidden]="!data.legalHold">
		</div>
		<div [ngClass]="styles.icon" class="glyphicons volume_up" [hidden]="!data.hasAudioOnly"></div>
		<div [ngClass]="styles.vbIcon" class="video-status-public-password" *ngIf="context?.isGuest && data.hasPassword" [title]="'PasswordProtected' | translate">
			<span class="vb-icon vb-icon-password"></span>
		</div>
		<vb-ui-thumbnail-sheet-preview class="thumbnail-preview" [ngClass]="styles.thumbnailSheetWrapper" [thumbnailUri]="thumbnailUri || (data.hasAudioOnly?'/shared/img/audio-thumbnail.png':'/shared/img/default-thumbnail.png')" [thumbnailSheetCfg]="thumbnailSheets" [width]="thumbnailWidth" [height]="thumbnailHeight" [msPerFrame]="233" [cssClass]="styles.thumbnailPreview">
		</vb-ui-thumbnail-sheet-preview>
		<video-thumbnail-status *ngIf="!config.hideThumbnailStatus" [approvalStatus]="data.approval?.status" [videoIsActive]="data.isActive" [videoStatus]="data.status" [videoThumbnailUri]="data.thumbnailUri" [cssClass]="styles.thumbnailStatus">
		</video-thumbnail-status>
	</div>
</a>
