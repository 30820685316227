import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { VideoThumbnailStatusComponent } from './VideoThumbnailStatus.Component';

@NgModule({
	declarations: [VideoThumbnailStatusComponent],
	exports: [VideoThumbnailStatusComponent],
	imports: [
		CommonModule,
		SpinnerAngularModule,
		TranslateModule
	]
})
export class VideoThumbnailStatusAngularModule {}
