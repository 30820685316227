<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>

<div class="search-icon search-dropdown dropdown" dropdown #searchDropdown="bs-dropdown" [autoClose]="true" [insideClick]="true">
		<button dropdownToggle class="btn main-nav-btn bg-none search-icon-btn dropdown-toggle" [ngClass]="{ open: searchDropdown.isOpen }" [attr.aria-label]="'Search' | translate ">
			<span class="glyphicons animated" [ngClass]="{
					'search theme-header-txt': !searchDropdown.isOpen,
					'remove_2 rotate-360-once theme-accent-txt': searchDropdown.isOpen
				}"></span>
		</button>
		<div *dropdownMenu class="dropdown-menu table-cell-fill search-cell">
			<search-header [showSearchText]="false" [buttonCssClasses]="'theme-accent-bg'">
			</search-header>
		</div>
</div>
