<dashboard-carousel [items]="events" [headerText]="'Dashboard_UpcomingEvents' | translate" headerSref="portal.scheduledEvents">

	<div class="carousel-cell" [ngClass]="styles.carouselCellItem" *ngFor="let item of events">

		<section [ngClass]="[styles.carouselCell, styles.eventCell]">

			<div [ngClass]="styles.imageWrapper">

				<div class="fixed-ratio-wrapper" [ngClass]="styles.fixedRatioWrapper">
					<div class="fixed-ratio">
						<a uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: item.id}" class="fixed-aspect-item" [attr.aria-label]="item.title">
							<img [alt]="'ThumbnailForAria' | translate : { '0': item.title }" class="preview-image anchored-thumbnail fill-thumbnail" [attr.data-flickity-lazyload]="item.previewThumbnailImageUri || '/shared/img/calendar-thumbnail.png'">
						</a>
					</div>
				</div>
				<div [ngClass]="styles.eventInfoContainer">
					<div [ngClass]="styles.eventInfoTitle" class="theme-primary-txt" [title]="item.title">
						<a uiSref="portal.scheduledEvents.webcast" class="theme-primary-txt" [uiParams]="{webcastId: item.id}">
							{{item.title}}
						</a>
					</div>
					<span [ngClass]="styles.eventInfoDetails" [title]="(item.startDate | vbDateTimeMedium) + ' - ' + (item.endDate | vbTimeShort)">
						{{(item.startDate | vbDateTimeMedium) + ' - ' + (item.endDate | vbTimeShort)}}
					</span>
				</div>
			</div>
		</section>
	</div>
</dashboard-carousel>
