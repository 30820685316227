import { Component } from '@angular/core';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { MainHeaderComponent } from './MainHeader.Component';

@Component({
	selector: 'classic-header',
	templateUrl: './ClassicHeader.Component.html'
})
export class ClassicHeaderComponent extends MainHeaderComponent {

	public themeStyleOverrides: IRules;

	public ngOnInit(): void {
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings));
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	public applyCssRules(brandingSettings: BrandingSettings): void {
		const themeAccentColor = brandingSettings.themeSettings.accentColor;
		const themeAccentFonterColor = brandingSettings.themeSettings.accentFontColor;
		const headerFontColor = brandingSettings.headerSettings.fontColor;
		const headerBackgroundColor = brandingSettings.headerSettings.backgroundColor;
		this.themeStyleOverrides = {
			'.theme-Classic .open .dropdown-toggle:before': {
				'border-bottom-color': `${headerBackgroundColor}`
			},
			'.theme-Classic notifications-menu-button.notification-cell .notifications': {
				'background-color': `${themeAccentColor}`
			},
			'.theme-Classic .help-nav-container .help-nav .help-nav-link:hover .new_window_alt': {
				'color': `${themeAccentFonterColor}`
			},
			'.theme-Classic .theme-Classic-search-header .form-control:not(:hover):not(:focus)': {
				'color': `${headerFontColor}`
			},
		};
	}

}
