import { Transition } from '@uirouter/angular';

import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { AdminCategoryService } from 'rev-shared/media/category/AdminCategory.Service';
import { EditCategoryComponent } from 'rev-shared/media/category/EditCategory.Component';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserSubscriptionsService } from 'rev-shared/userSubscription/UserSubscriptions.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { MEDIA_STATE_ALL, MEDIA_STATE_BROWSE } from '../MediaStateDeclarations';
import { SearchFiltersSidebarComponent } from 'rev-portal/media/search/SearchFiltersSidebar.Component';
import { CategoryService } from 'rev-shared/media/Category.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';

const filterParentStates = [
	MEDIA_STATE_ALL,
	MEDIA_STATE_BROWSE,
	'portal.media.uploads',
	'portal.media.search',
	'portal.media.pending-videos',
	'portal.media.edit',
	'portal.media.category-detail',
	'portal.media.expirations',
	'portal.media.my-subscriptions'
];

export const searchStates: IVbNg2StateDeclaration[] = [
	...filterParentStates.map( (state: string) => ({
		name: `${state}.filters-sidebar`,
		allowGuestAccess: true,
		url: '/filters',
		views: {
			['right-sidebar-content@' + state]: {
				component: SearchFiltersSidebarComponent
			}
		},
		params: { tableDataMode: null },
		resolve: [
			{
				token: 'isGuestUser',
				deps: [UserContextService],
				resolveFn: (UserContext: UserContextService) => UserContext.isGuest()
			},
			{
				token: 'teams',
				deps: ['isGuestUser', VideoService, UserSubscriptionsService],
				resolveFn: (isGuestUser: boolean, VideoService: VideoService, UserSubscriptions: UserSubscriptionsService) => {

					if (isGuestUser) {
						return [];
					}

					return VideoService.getViewableTeams()
						.then((teams: any[]) => {
							if (state === 'portal.media.my-subscriptions') {
								return (teams || []).filter(team => UserSubscriptions.contentSubscriptions?.channel?.[team.teamId]);
							}
							return teams;
						});
				}
			},
			{
				token: 'categories',
				deps: [MediaFeaturesService, CategoryService, 'accountId'],
				resolveFn: ( MediaFeatures: MediaFeaturesService, CategoryService: CategoryService, accountId: string) => {
					return MediaFeatures.accountFeatures.enableCategories ? CategoryService.getFlattenedCategories(accountId, true) : [];
				}
			},
			{
				token: 'tableDataMode',
				deps: [Transition],
				resolveFn: (t: Transition) => t.params().tableDataMode
			},
		]
	})),
	{
		name: 'portal.edit-category',
		url: '/category/edit?categoryId&parentCategoryId',
		params: {
			categoryId: null,
			parentCategoryId: null
		},
		component: EditCategoryComponent,
		resolve: [{
			token: 'categoryId',
			deps: [Transition],
			resolveFn: (t: Transition) => t.params().categoryId
		}, {
			token: 'parentCategoryId',
			deps: [Transition],
			resolveFn: (t: Transition) => t.params().parentCategoryId
		}, {
			token: 'category',
			deps: [AdminCategoryService, 'categoryId', 'parentCategoryId', UserContextService, SecurityContextService],
			resolveFn: (AdminCategoryService: AdminCategoryService, categoryId: string, parentCategoryId: string, UserContext: UserContextService, SecurityContext: SecurityContextService) =>
				categoryId ? AdminCategoryService.getCategory(categoryId) : {
					parentCategoryId,
					accountId: UserContext.getAccount().id,
					categoryPolicyItems: !SecurityContext.checkAuthorization('admin.media.categories.edit') ? [{
						id: UserContext.getUser().id,
						type: AccessEntityType.User,
						categoryManager: true
					}] : []
				}
		}, {
			token: 'cancelSref',
			deps: [Transition],
			resolveFn: (transition: Transition) => ({
				name: transition.from().name || MEDIA_STATE_BROWSE,
				params: transition.params('from')
			})
		}]
	}
];
