<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>

<div class="scrollable-area-wrapper padding-top-15" [ngClass]="{'has-items':unreadNotifications.length}">
	<ul class="list-unstyled scrollable-area">
		<li *ngFor="let notification of unreadNotifications | slice:0:10" role="menuitem" class="media" (click)="markAsRead(notification.id)">
			<vb-notification [notification]="notification"></vb-notification>
		</li>

		<li *ngIf="!unreadNotifications.length" role="menuitem" class="no-notifications-msg">
			<h4>{{ 'Notifications_NoNotifications' | translate }}</h4>
			<a uiSref="portal.user-notifications" (click)="contentClosed()">
				{{ 'Notifications_SeeAllNotifications' | translate }}
			</a>
		</li>
	</ul>
</div>

<div class="menu-footer group" [hidden]="!(unreadNotifications.length)">
	<button type="button" class="btn-transparent theme-header-txt float-left" role="menuitem" (click)="markAllAsRead()">
		{{ 'Notifications_MarkAllAsRead' | translate }}
	</button>
	<a class="theme-header-txt float-right" role="menuitem" uiSref="portal.user-notifications" (click)="contentClosed()">
		{{ 'Notifications_SeeAllNotifications' | translate }}
	</a>
</div>
