import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

import { formatIsoDate, formatShortTime } from 'rev-shared/date/DateFormatters';
import { parentControlContainerProvider } from 'rev-shared/util/directives/form/validation/validationUtil';
import { DateUtil } from 'rev-shared/date/DateUtil';

import { VideoSettingsService } from 'rev-portal/media/videos/VideoSettings/VideoSettings.Service';

import {
	IExpirationDetails,
	IExpirationSelected
} from './ExpirationOptions.Interface';

export enum ExpirationOptions {
	NONE = 'None',
	DATE = 'Date',
	RULE = 'Rule'
}

export enum RuleTypes {
	DAYS_AFTER_UPLOAD = 'DaysAfterUpload',
	DAYS_WITHOUT_VIEW = 'DaysWithoutViews',
	NONE = 'None'
}

export class IExpirationConfig {
	public hideLabel: boolean;
	public hideExpirationOption: ExpirationOptions;
	public hideExpirationOptionLabel: boolean;
	public notThemed: boolean;
}

import './video-expiration-options.less';

@Component({
	selector: 'vb-video-expiration-options',
	templateUrl: './VbVideoExpirationOptions.Component.html',
	viewProviders: [parentControlContainerProvider()]
})

export class VbVideoExpirationOptionsComponent implements OnInit {
	@Input() public config: IExpirationConfig = new IExpirationConfig();
	@Input() public expirationDetails: IExpirationDetails;
	@Input() public expirationRuleEnabled: boolean;
	@Input() public expirationRules: any;
	@Input() public hideNoneOption: boolean;
	@Input() public isLarge: boolean;
	@Input() public whenUploaded: Date;
	@Input() public lastViewed: Date;
	@Input() public themedLabel: boolean;

	@Output() public onExpirationChange: EventEmitter<any> = new EventEmitter();

	public readonly midnight: string = formatShortTime(DateUtil.getToday());
	public readonly tomorrow: Date = DateUtil.addDays(DateUtil.getToday(), 1);

	public expirationOptions: typeof ExpirationOptions;
	public ruleTypes: typeof RuleTypes;

	public expirationRule: IExpirationSelected;
	public expirationOption: string;

	constructor (
		private VideoSettingsService: VideoSettingsService
	) { }

	public ngOnInit(): void {
		this.expirationOptions = ExpirationOptions;
		this.ruleTypes = RuleTypes;

		const ruleType = this.expirationDetails.expiryRuleType;

		this.expirationOption = (ruleType === ExpirationOptions.DATE ||
			ruleType === ExpirationOptions.NONE && !!this.expirationDetails.expirationDate) ?
			ExpirationOptions.DATE :
			ruleType && ruleType !== RuleTypes.NONE ?
				ExpirationOptions.RULE :
				ExpirationOptions.NONE;

		this.updateAssignedExpirationRule();
	}

	public onExpiryRuleChange(selectedRule: any): void {
		this.expirationRule.expiryRuleDate = selectedRule?.numberOfDays ?
			this.getExpirationDate(selectedRule.ruleType, selectedRule.numberOfDays) :
			undefined;
		this.onExpirationUpdate();
	}

	public onExpirationUpdate(): void {
		const updatedExpiration = this.retrieveUpdatedExpiration();

		if(this.onExpirationChange) {
			this.onExpirationChange.emit(updatedExpiration);
		}
	}

	private retrieveUpdatedExpiration(): IExpirationDetails {
		switch(this.expirationOption) {
			case this.expirationOptions.DATE:
				return this.onExpirationUpdateTypeDate();
			case this.expirationOptions.RULE:
				return this.onExpirationUpdateTypeRule();
			default:
				return this.onExpirationUpdateTypeNone();

		}
	}

	private onExpirationUpdateTypeDate(): IExpirationDetails {
		return {
			ruleId: null,
			expiryRuleType: this.ruleTypes.NONE,
			expirationDate: this.expirationDetails.expirationDate,
			deleteOnExpiration: !!this.expirationDetails.deleteOnExpiration,
			numberOfDays: 0
		};
	}

	private onExpirationUpdateTypeRule(): IExpirationDetails {
		if (!this.expirationRule) {
			return {
				ruleId: null,
				expiryRuleType: null,
				expirationDate: null,
				deleteOnExpiration: null,
				numberOfDays: null
			};
		}

		return {
			ruleId: this.expirationRule.id,
			expiryRuleType: this.expirationRule.ruleType,
			expirationDate: DateUtil.addDays(DateUtil.getToday(), this.expirationRule.numberOfDays),
			deleteOnExpiration: this.expirationRule.deleteOnExpire,
			numberOfDays: this.expirationRule.numberOfDays
		};
	}

	private onExpirationUpdateTypeNone(): IExpirationDetails {
		return {
			deleteOnExpiration: false,
			expirationDate: null,
			expiryRuleType: this.ruleTypes.NONE,
			numberOfDays: 0,
			ruleId: null
		};
	}

	private updateAssignedExpirationRule(): void {
		if (this.expirationDetails) {
			this.expirationRule = this.expirationRules.rules.find(item => item.id === this.expirationDetails.ruleId);

			if (this.expirationDetails.ruleId && this.expirationRule) {
				this.expirationRule.expiryRuleDate = formatIsoDate(this.expirationDetails?.expirationDate) || this.getExpirationDate(this.expirationRule.ruleType, this.expirationRule.numberOfDays);
			}
		}
	}

	private getExpirationDate(ruleType: RuleTypes, numberOfDays: number): string {
		return this.VideoSettingsService.getVideoExpiration(ruleType, numberOfDays, this.lastViewed);
	}
}
