import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';

import { VbUiAccentHeaderComponent } from './VbUiAccentHeader.Component';

const components = [VbUiAccentHeaderComponent];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		CssRulesAngularModule
	]
})
export class AccentHeaderAngularModule {
}
