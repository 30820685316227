<div [hidden]="isApprovalApproved
		&& videoIsActive
		&& !isProcessingFailed
		&& !isProcessing" class="video-thumbnail-status" [ngClass]="[
		!videoThumbnailUri && !videoIsActive ? 'fill' : '',
		isProcessingFailed ? 'error' : '',
		isApprovalRejected ? 'rejected' : '',
		isApprovalPending ? 'pending' : '',
		!videoIsActive && (!approvalStatus || isApprovalApproved) ? 'inactive' : '',
		isProcessing || isUploading ? 'processing' : '',
		cssClass ? cssClass : '']">
	<span *ngIf="!videoIsActive
			&& (!approvalStatus || isApprovalApproved)
			&& !isProcessing
			&& !isUploading" class="status-inactive glyphicons eye_close" [attr.aria-label]="'Media_Videos_Playback_VideoIsInactive' | translate" [title]="'Media_Videos_Playback_VideoIsInactive' | translate"></span>

	<span *ngIf="isProcessingFailed" [attr.aria-label]="'ProcessingFailed' | translate" [title]="'ProcessingFailed' | translate" class="glyphicons circle_exclamation_mark video-error">
	</span>

	<span *ngIf="approvalStatus
			&& !isApprovalApproved
			&& !isApprovalRejected
			&& !isUploading
			&& !isProcessingFailed
			&& !isProcessing" class="glyphicons cogwheel rotate-360-slow stop-animation-flickering" [attr.aria-label]="'ApprovalWorkflow_PendingApproval' | translate" [title]="'ApprovalWorkflow_PendingApproval' | translate">
	</span>

	<span *ngIf="isApprovalRejected" class="glyphicons remove_2" [attr.aria-label]="'ApprovalWorkflow_VideoRejectedResubmitMessage' | translate" [title]="'ApprovalWorkflow_VideoRejectedResubmitMessage' | translate">
	</span>

	<div *ngIf="isProcessing || isUploading" class="status-processing">
		<vb-loading-spinner [block]="true" [iconClass]="'vb-icon vb-icon-camera-plus'" [msg]="(isUploading ? 'Video_Playback_VideoUploading' : 'Video_Playback_VideoProcessing') | translate" [msgDisplayBelowSpinner]="true" [size]="spinnerSize">
		</vb-loading-spinner>
	</div>
</div>
