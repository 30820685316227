<widget-wrapper [headerText]="'Navigation_MyTeams' | translate" [headerLink]="'portal.team'">

	<a *ngIf="teams?.length" uiSref="portal.team" vbBtnPrimary [ngClass]="styles.seeAllLink">
		{{ 'Dashboard_SeeAll' | translate }}
	</a>

	<vb-loading-spinner [block]="true" [size]="'large'" *ngIf="isFetching; else ready">
	</vb-loading-spinner>

	<ng-template #ready>
		<section *ngIf="teams.length; else noTeams" [ngClass]="{'animated fadeIn': !isFetching}">
			<ul [ngClass]="styles.listWrap">
				<li *ngFor="let team of teams" [ngClass]="styles.listItem">
					<a [ngClass]="styles.listItemText" uiSref="portal.media" [uiParams]="{team: team.id}">
						<span>
							{{team.name}}
						</span>
					</a>
				</li>
			</ul>
		</section>
		<ng-template #noTeams>
			<section [ngClass]="styles.noResultsContainer" class="text-align-center padding-top-15">
				<span class="control-label">
					{{ 'Teams_NotAvailable' | translate }}
				</span>
				<div class="padding-top-10" *ngIf="TeamService.isCreateTeamAccess">
					<a class="theme-accent-link" uiSref="portal.team-edit">
						{{ 'Teams_Create_New' | translate }}
					</a>
				</div>
			</section>
		</ng-template>

	</ng-template>
</widget-wrapper>
