import { defaultColors } from 'rev-portal/branding/Defaults';

enum TeamRoleType {
	TeamAdmin = 'TeamAdmin',
	TeamContributor = 'TeamContributor',
	TeamUploader = 'TeamUploader',
	TeamMember = 'TeamMember'
}

enum EntityType {
	User = 'User',
	Group = 'Group',
	Team = 'Team',
	Role = 'Role',
	Collection = 'Collection'
}

class Team {
	constructor(accountId: string, teamCreator: string = null) {

		this.bgColor = defaultColors.accentColor;
		this.themeColor = defaultColors.channelFont;
		this.accountId = accountId;

		this.teamMembers = teamCreator ? [{
			id: teamCreator,
			type: EntityType.User,
			isAdmin: true,
			roleTypes: [TeamRoleType.TeamAdmin]
		}] : [];
	}

	public accountId: string;
	public bgColor?: string;
	public description?: string;
	public id?: string;
	public imageId?: string;
	public imageUrl?: string;
	public isNew?: boolean;
	public name: string;
	public linkId?: string;
	public teamMembers?: ITeamMember[];
	public themeColor?: string;
	public thumbnailUri?: string;
	public totalVideos?: number;
}

export interface ITeamMember {
	id: string;
	type: EntityType;
	isAdmin: boolean;
	roleTypes: string[];
}

interface ITeam {
	accountId: string,
	bgColor: string,
	description: string,
	id: string,
	name: string,
	themeColor: string,
	thumbnailUri: string,
	totalVideos: number
}

class StatusHandler{
	private statusState: { [status: string]: boolean };

	public get status() {
		return this.statusState;
	}

	public setLoadingStatus(): void {
		this.statusState = { loading: true };
	}

	public setActiveStatus(): void {
		this.statusState = { active: true };
	}

	public setErrorStatus(): void {
		this.statusState = { error: true };
	}

}

export {
	ITeam,
	StatusHandler,
	Team,
	TeamRoleType
};
