import type { GridApi, IHeaderParams, RowClickedEvent, GridSizeChangedEvent, RowSelectedEvent, GridReadyEvent, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throttle, escape } from 'underscore';

import { INFINITE_GRID_BATCH_LOAD_COMPLETE } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGridDataSource';
import { ISelectionChangedEvent, SELECTION_EVENTS } from 'rev-shared/ui/dataGrid/cellRenderers/HeaderSelectAllCheckboxRenderer.Component';
import { IVbUiDataGridColDef } from 'rev-shared/ui/dataGrid/columns/IVbUiDataGridColDef';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbUiInfiniteScrollGridComponent } from 'rev-shared/ui/dataGrid/infiniteScroll/VbUiInfiniteScrollGrid.Component';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { allowTabbingIntoInnerContent, clickAnchorChild, isOnlyFocusableChildAnchorTag } from 'rev-shared/ui/dataGrid/DataGridUtil';
import { formatTimespanShort } from 'rev-shared/date/DateFormatters';
import { mediumDateValueFormatter, mediumDateTimeValueFormatter } from 'rev-shared/ui/dataGrid/valueFormatters/MediumDateTimeValueFormatter';

import { VIDEO_PLAYBACK_STATE_NAME } from 'rev-portal/media/videos/videoPlayback/Constants';

import { CategoryRowRendererComponent } from './CategoryRowRenderer.Component';
import { ITableViewApi, ROW_HEIGHT_LARGE, ROW_HEIGHT_SMALL, TABLE_DATA_MODE, IMAGE_WIDTH_LARGE, IMAGE_WIDTH_SMALL } from './Contract';
import { StateService } from '@uirouter/angular';
import { VideoSearchResultsComponent } from '../VideoSearchResults.Component';
import { VideoSelectionModelService } from '../bulkEdit/VideoSelectionModel.Service';
import { VideoThumbnailCellRendererComponent } from './VideoThumbnailCellRenderer.Component';
import { VideoTitleCellRendererComponent } from './VideoTitleCellRenderer.Component';

import styles from './VideoSearchResultsTable.Component.module.less';

@Component({
	selector: 'video-search-results-table',
	templateUrl: './VideoSearchResultsTable.Component.html',
	host: {
		'[class]': 'styles.root',
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill'
	},
})
export class VideoSearchResultsTableComponent {
	@Input() public tableDataMode: TABLE_DATA_MODE;
	@Input() public isSmallSize: boolean;
	@Input() public columnSort: { colId: string; sort: string};
	@Output() public tableViewReady: EventEmitter<ITableViewApi> = new EventEmitter<ITableViewApi>();

	private gridApi: GridApi;
	private batchLoadResolve: (value?: any) => void;
	private batchLoadComplete = () => this.batchLoadResolve?.();
	private isSmallSizeCopy: boolean;

	@ViewChild(VbUiInfiniteScrollGridComponent, { static: true })
	public grid: VbUiInfiniteScrollGridComponent;

	public dataMode: { [key in TABLE_DATA_MODE]?: boolean };
	public categoryMode: boolean;
	public selectAllHeaderParams: Partial<IHeaderParams>;
	public userId: string;
	public rowHeight;
	public bulkEdit: boolean;
	public imgWidth: number;
	public hiddenColumns: { [key: string]: boolean };
	public columnDefs: IVbUiDataGridColDef[];

	public readonly pageSize = this.videoSearchResults.pageSize;
	public readonly styles = styles;
	public readonly mediumDateTimeValueFormatter = mediumDateTimeValueFormatter;
	public readonly mediumDateValueFormatter = mediumDateValueFormatter;
	public readonly gridSizeChanged = throttle((event: GridSizeChangedEvent) => this.onGridSizeChanged(event), 1000);
	public readonly profilePicRendererParams = {
		getProfilePicUrl: video => video.ownerProfileImageUri,
		uiSref: VIDEO_PLAYBACK_STATE_NAME,
		uiSrefParams: video => ({ videoId:video.id, config: this.videoSearchResults.videoPlaybackConfig }),
		cssClass: `box-block height-full ${styles.cellPseudoAnchor}`
	};
	public readonly videoLinkCellRenderer = params => {
		const link = this.$state.href(VIDEO_PLAYBACK_STATE_NAME, { videoId: params.data?.id });
		return `
			<div display="flex" class="height-full theme-primary-txt">
				<a href="${link}" flex=fill class="${styles.cellPseudoAnchor}" aria-label="${this.translateService.instant('Media_Video_GoToVideo', { 0: escape(params.data?.title) })}">
					${params.valueFormatted ? params.valueFormatted : params.value == null ? '' : params.value}
				</a>
			</div>
		`;
	};
	public readonly videoDurationFormatter = params => {
		const video = params?.data || {};
		return video.isLive ? this.translateService.instant('Live')
			: formatTimespanShort(video.duration) || this.translateService.instant('NotAvailable');
	};
	public readonly videoViewCountFormatter = params => {
		return params?.data?.viewCount > -1 ? params?.data?.viewCount : 0;
	};

	public readonly deleteOnExpirationFormatter = params => {
		return params?.data?.expiryDate ?
			params.value ? this.translateService.instant('Media_Videos_ExpirationStatus_DeletePending') : this.translateService.instant('Media_Videos_ExpirationStatus_ExpirationPending')
			: '';
	};

	constructor(
		private translateService: TranslateService,
		public videoSearchResults: VideoSearchResultsComponent,
		public UserContext: UserContextService,
		private VideoSelectionModel: VideoSelectionModelService,
		private $state: StateService
	) {}

	public ngOnInit(): void {
		this.isSmallSizeCopy = this.isSmallSize;
		this.rowHeight = this.getRowHeight();
		this.imgWidth = this.getImageWidth();
		this.categoryMode = this.tableDataMode === TABLE_DATA_MODE.Category;
		this.dataMode = { [TABLE_DATA_MODE[this.tableDataMode]]: true };
		this.selectAllHeaderParams = { context:  { grid: this.grid, isThemed: true, trackSelectAllOnly: true } };
		this.bulkEdit = this.videoSearchResults.bulkEdit;
		this.userId = this.UserContext.getUser().id;
		this.setColumnsDisplay();
		this.assignColumnDefs();
	}

	public ngAfterViewInit(): void {
		this.grid.components = {
			...this.grid.components,
			videoTitleRenderer: VideoTitleCellRendererComponent,
			videoThumbnailRenderer:  VideoThumbnailCellRendererComponent,
			categoryFullWidthRendererFunc: CategoryRowRendererComponent
		};
		this.grid.context = {
			...this.grid.context,
			videoPlaybackConfig: this.videoSearchResults.videoPlaybackConfig,
			isGuest: this.videoSearchResults.isGuest,
			hasMediaEditAuth: this.videoSearchResults.hasMediaEditAuth,
			hasEditVideoAuth: this.videoSearchResults.hasEditVideoAuth,
			query: this.videoSearchResults.query,
			dataMode: this.dataMode,
			getRowHeight: () => this.getRowHeight(),
			getImageWidth: () => this.getImageWidth(),
			isSmallSize: this.isSmallSize
		};

		this.grid.headerHeight = this.categoryMode ? 0 : 25; // 25px is the ag-grid default

		this.grid.rowClassRules = {
			'tableViewRow': () => true,
			'video-owner': params => params.data?.uploaderUserId === this.userId,
			[styles.processingError]: params => params.data && this.processingFailed(params.data),
			[styles.inactive]: params => params.data && params.data.isActive === false,
			'pending-approval': params => {
				const video = params.data;

				return video && video.approval?.status
				&& video.approval?.status !== this.videoSearchResults.approvalStatusOptions.APPROVED
				&& video.approval?.status !== this.videoSearchResults.approvalStatusOptions.REJECTED;
			},
			'rejected': params => params.data && params.data.approval?.status === this.videoSearchResults.approvalStatusOptions.REJECTED
		};

		this.grid.defaultColDef = {
			...this.grid.defaultColDef,
			cellClass: styles.cellClass,
			suppressMovable: true,
			headerClass: styles.headerClass,
			suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
				const event = params.event;
				const eventEl = (event.srcElement as HTMLElement);

				if ((event.code === 'Tab' || event.key === 'Tab') && !isOnlyFocusableChildAnchorTag(eventEl)) {
					return allowTabbingIntoInnerContent(params);
				} else if ((event.code === 'Enter' || event.key === 'Enter') && isOnlyFocusableChildAnchorTag(eventEl)) {
					clickAnchorChild(params);
					return true;
				}
				return false;
			}
		};

	}

	public onGridReady(event: GridReadyEvent): void {
		this.gridApi = event.api;
		this.gridApi.addEventListener(SELECTION_EVENTS.VbSelectAllChanged, event => this.onSelectAllChanged(event));
		this.gridApi.addEventListener(INFINITE_GRID_BATCH_LOAD_COMPLETE, this.batchLoadComplete);
		this.tableViewReady.emit({
			reloadTableView: () => (this.gridApi.getModel() as any).reset(),
			loadNexBlockData: () => {
				return new Promise((resolve, reject) => {
					const cacheBlock = this.gridApi.getCacheBlockState();
					const blockIds = Object.keys(cacheBlock).map(key => +key);
					let maxBlockId = Math.max.apply(null, blockIds);
					/* eslint-disable */
					this.gridApi['infiniteRowModel'].infiniteCache.createBlock(++maxBlockId);
					this.batchLoadResolve = resolve;
				});
			},
			sortChanged: this.grid.sortChanged,
			resetSelection: () => {
				this.gridApi.setColumnDefs(this.gridApi.getColumnDefs());
			}
		});
	}

	public onGridSizeChanged(event: GridSizeChangedEvent): void {
		if (this.isSmallSize === this.isSmallSizeCopy) {
			return;
		}
		this.isSmallSizeCopy = this.isSmallSize;
		//Tried to use redraw and refresh cells and both didnot work in case of infinite grid.
		this.$state.reload();
	}

	public onRowDataUpdated($event: GridApi): void {
		//use later
	}

	public onRowClickEvent($event: RowClickedEvent): void {
		//use later
	}

	private isProcessing(video: any): boolean {
		return video.status === VideoStatus.PROCESSING ||
			video.status === VideoStatus.UPLOADING_FINISHED;
	}

	private isUploading(video: any): boolean {
		return video.status === VideoStatus.NOT_UPLOADED ||
			video.status === VideoStatus.UPLOADING;
	}

	private processingFailed(video: any): boolean {
		return video.status === VideoStatus.UPLOAD_FAILED ||
			video.status === VideoStatus.READY_BUT_PROCESSING_FAILED ||
			video.status === VideoStatus.PROCESSING_FAILED;
	}

	private onSelectAllChanged(event: ISelectionChangedEvent): void {
		this.VideoSelectionModel.toggleSelectAll();
	}

	public onRowSelected(event: RowSelectedEvent): void {
		this.VideoSelectionModel.toggleSelection(event.node.data, event.node.isSelected());
	}

	private getRowHeight(): number {
		return this.isSmallSize ? ROW_HEIGHT_SMALL : ROW_HEIGHT_LARGE;
	}

	private getImageWidth(): number {
		return this.isSmallSize ? IMAGE_WIDTH_SMALL : IMAGE_WIDTH_LARGE;
	}

	private setColumnsDisplay(): void {
		this.hiddenColumns = {
			checkBox: !this.bulkEdit,
			thumbnailUri: this.dataMode.Category,
			title: this.dataMode.Category,
			whenUploaded: this.dataMode.Category || this.dataMode.Expiration,
			ownerName: this.isSmallSize || this.dataMode.Category,
			duration: this.isSmallSize || this.dataMode.Category || this.dataMode.Expiration,
			viewCount: this.isSmallSize || this.dataMode.Category || this.dataMode.Expiration,
			lastViewed: this.isSmallSize || this.dataMode.Category || this.dataMode.Expiration,
			deleteOnExpiration: this.isSmallSize || !this.dataMode.Expiration,
			expiryDate: !this.dataMode.Expiration,
		};
	}

	private assignColumnDefs(): void {
		this.columnDefs = [
			{
				headerName: this.translateService.instant('SelectAll'),
				headerComponent: 'headerSelectAllCheckbox',
				headerComponentParams: this.selectAllHeaderParams,
				checkboxSelection: true,
				headerClass: this.styles.chkBoxHeaderCellClass,
				cellClass: this.styles.checkBoxCellClass,
				hide: this.hiddenColumns.checkBox,
				minWidth: this.isSmallSize ? 50 : 80,
				maxWidth: this.isSmallSize ? 50 : 80
			},
			{
				headerName: '',
				field: 'thumbnailUri',
				maxWidth: this.imgWidth,
				minWidth: this.imgWidth,
				hide: this.hiddenColumns.thumbnailUri,
				cellRenderer: 'videoThumbnailRenderer'
			},
			{
				headerName: this.translateService.instant('Title'),
				field: 'title',
				cellRenderer: 'videoTitleRenderer',
				minWidth: this.isSmallSize ? 150 : 350,
				sortable: true,
				hide: this.hiddenColumns.title
			},
			{
				headerName: this.translateService.instant('Media_Videos_UploadDate'),
				field: 'whenUploaded',
				sortable: true,
				cellRenderer: this.videoLinkCellRenderer,
				hide: this.hiddenColumns.whenUploaded,
				minWidth: 180,
				maxWidth: 220,
				valueFormatter: this.mediumDateTimeValueFormatter
			},
			{
				headerName: this.translateService.instant('Media_Videos_Owner'),
				field: 'ownerName',
				cellRenderer: 'profilePicture',
				sortable: true,
				minWidth: 100,
				maxWidth: 150,
				hide: this.hiddenColumns.ownerName,
				cellRendererParams: this.profilePicRendererParams
			},
			{
				headerName: this.translateService.instant('Duration'),
				field: 'duration',
				sortable: true,
				hide: this.hiddenColumns.duration,
				minWidth: 100,
				maxWidth: 120,
				cellRenderer: this.videoLinkCellRenderer,
				valueFormatter: this.videoDurationFormatter
			},
			{
				headerName: this.translateService.instant('Media_Videos_Views'),
				field: 'viewCount',
				sortable: true,
				hide: this.hiddenColumns.viewCount,
				minWidth: 80,
				maxWidth: 100,
				cellRenderer: this.videoLinkCellRenderer,
				valueFormatter: this.videoViewCountFormatter
			},
			{
				headerName: this.translateService.instant('Media_Videos_LastViewedDate'),
				field: 'lastViewed',
				sortable: true,
				hide: this.hiddenColumns.lastViewed,
				minWidth: 180,
				maxWidth: 220,
				cellRenderer: this.videoLinkCellRenderer,
				valueFormatter: this.mediumDateTimeValueFormatter
			},
			{
				headerName: this.translateService.instant('Media_Videos_ExpirationStatus'),
				field: 'deleteOnExpiration',
				sortable: true,
				hide: this.hiddenColumns.deleteOnExpiration,
				cellRenderer: this.videoLinkCellRenderer,
				valueFormatter: this.deleteOnExpirationFormatter
			},
			{
				headerName: this.translateService.instant('Media_Videos_ExpirationEffectiveDate'),
				field: 'expiryDate',
				sortable: true,
				hide: this.hiddenColumns.expiryDate,
				cellRenderer: this.videoLinkCellRenderer,
				valueFormatter: this.mediumDateValueFormatter
			}
		];
	}
}
