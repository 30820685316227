import { Pipe, PipeTransform } from '@angular/core';
import { sortFieldMapping } from './tableLayout/Contract';

@Pipe({
	name: 'tableViewColumnMap'
})
export class TableViewColumMapPipe implements PipeTransform {

	public transform(value: string): string {
		const key = Object.keys(sortFieldMapping).find(key => sortFieldMapping[key] === value);

		return key || value;
	}

}
