import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { VbrickPlayerModule } from '@vbrick/vbrick-player';

import { AngularAppBootstrapDepsModule } from 'rev-shared/bootstrap/AngularAppBootstrapDeps.Module';
import { EntryPointAngularModule } from 'rev-shared/entryPoint/EntryPointAngular.Module';
import { VbrickSharedMediaModule } from 'rev-shared/media/Media.Module';
import { VideoPlaylistModule } from 'rev-shared/media/videoPlaylist/VideoPlaylist.Module';

import { AdminLoaderModule } from './admin/AdminLoader.Module';
import { DashboardModule } from './dashboard/Dashboard.Module';
import { DebugLoaderModule } from './debugModule/DebugLoader.Module';
import { DownloadDetailsModule } from './downloads/DownloadDetails.Module';
import { MediaModule } from './media/Media.Module';
import { NavigationAngularModule } from './navigation/NavigationAngular.Module';
import { NotificationAngularModule } from './notifications/NotificationAngular.Module';
import { ScheduledEventsLoaderModule } from './scheduledEvents/ScheduledEventsLoader.Module';
import { SparkRootModule } from 'rev-portal/media/spark/Spark.Module';
import { TemplatesRootModule } from 'rev-shared/ui/templates/TemplatesAngular.Module';
import { UserProfileModule } from './userProfile/UserProfile.Module';

import { configureRouter, states } from './PortalStatesV2.Config';
import { PortalAppComponent } from './PortalApp.Component';

@NgModule({
	bootstrap: [PortalAppComponent],
	imports: [
		AdminLoaderModule,
		AngularAppBootstrapDepsModule,
		DashboardModule,
		DebugLoaderModule,
		DownloadDetailsModule,
		EntryPointAngularModule,
		MediaModule,
		NavigationAngularModule,
		NotificationAngularModule,
		ScheduledEventsLoaderModule,
		SparkRootModule,
		TemplatesRootModule,
		UIRouterModule.forRoot({ config: configureRouter, states, useHash: true }),
		UserProfileModule,
		VbrickPlayerModule,
		VbrickSharedMediaModule,
		VideoPlaylistModule
	]
})
export class PortalAngularModule {}
