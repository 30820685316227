
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/angular';

import { Observable, of, from, forkJoin, merge } from 'rxjs';
import { map, switchMap, startWith, scan, shareReplay, first } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';

import { AccessControl, ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { CategoryService } from 'rev-shared/media/Category.Service';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { ICategory } from 'rev-shared/media/Media.Contract';
import { IVideoSettings } from 'rev-shared/media/videoSettings/VideoSettings.Contract';
import { IVideoThumbnail } from 'rev-shared/media/videoSettings/IVideoThumbnail';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushService } from 'rev-shared/push/PushService';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserAuthorizationService } from 'rev-shared/security/UserAuthorization.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VIDEO_PLAYBACK_ROUTE } from 'rev-shared/media/Constants';
import { VIEWER_ID_SETTING_HELPER } from 'rev-shared/viewerId/ViewerIdHelper';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { VideoTemplatesService } from 'rev-shared/video/VideoTemplates.Service';
import { ViewerIdPolicy } from 'rev-shared/viewerId/ViewerIdContract';
import { formatIsoDate } from 'rev-shared/date/DateFormatters';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { IExpirationDetails } from 'rev-portal/media/videos/videoExpiration/Expiration.Interface';
import { RuleTypes } from 'rev-portal/media/videos/videoExpirationOptions/VbVideoExpirationOptions.Component';

import { VideoSupplementalFilesService } from './advanced/video-supplemental-files/VideoSupplementalFiles.Service';
import { IVideoStatus } from './VideoSettings.Contract';

export const VideoSettingsRouteScope = 'Media.VideoSettings';

export enum TagUsersStatus {
	WaitingForSubmission = 'WaitingForSubmission',
	Submitted = 'Submitted',
	InProcess = 'InProcess',
	Finished = 'Finished',
	Failed = 'Failed'
}

@Injectable({
	providedIn: 'root'
})
export class VideoSettingsService {

	public videoSettings$: Observable<IVideoSettings>;
	public videoStatus$: Observable<IVideoStatus>;

	constructor(
		private http: HttpClient,
		private CategoryService: CategoryService,
		private $uiRouterGlobals: UIRouterGlobals,
		private DateParsers: DateParsersService,
		private MediaFeatures: MediaFeaturesService,
		private PushBus: PushBus,
		private PushService: PushService,
		private UserContext: UserContextService,
		private VideoSupplementalFilesService: VideoSupplementalFilesService,
		private VideoTemplatesService: VideoTemplatesService,
		private SecurityContext: SecurityContextService,
		private UserAuthorization: UserAuthorizationService
	) {
		'ngInject';

		this.videoSettings$ = this.$uiRouterGlobals.params$
			.pipe(
				switchMap(({ videoId }) => this.getVideoSettings(videoId)),
				tag<any>('videoSettings$'),
				shareReplay({ bufferSize: 1, refCount: true })
			);

		this.videoStatus$ = this.videoSettings$.pipe(
			map(settings => ({
				id: settings.id,
				tagUsersJob: settings.tagUsersJob,
				hasFacialRecognitionInstance: settings.hasFacialRecognitionInstance,
				hasTranscriptionInstance: settings.hasTranscriptionInstance,
				facialInstanceSizeError: settings.facialInstanceSizeError,
				transcriptionInstanceTooLarge: settings.transcriptionInstanceTooLarge,
				isProcessing: settings.isProcessing,
				status: settings.status,
				instances: settings.instances
			} as IVideoStatus)),
			switchMap(settings => this.subscribePush(settings.id).pipe(
				scan((a, b) => ({ ...a, ...b }), settings),
				startWith(settings)
			))
		);
	}

	private getVideoSettings(videoId: string): Observable<IVideoSettings> {
		return forkJoin(
			from(lastValueFrom(this.http.get<any>(`/media/videos/${videoId}/settings`))),
			from(this.CategoryService.getFlattenedCategories(this.UserContext.getAccount().id))
		).pipe(
			map(([{ video }, categories]) => this.readVideo(video, categories))
		);
	}

	private readVideo(video: any, categories: ICategory[]): IVideoSettings {
		const {
			accessControl,
			accessControlEntities,
			password,
			thumbnailUri,
			imageId,
			categoryIds,
			enableExternalApplicationAccess,
			enableExternalViewersAccess,
			...settings
		} = video;

		const features = this.MediaFeatures.accountFeatures;

		return {
			...settings,
			chapters: settings.chapters?.chapters.map(chapter => ({
				...chapter,
				time: this.DateParsers.parseTimespan(chapter.time),
			})),
			enableAutoShowChapterImages: video.enableAutoShowChapterImages ?? true,
			publishDate: this.DateParsers.parseIsoDate(video.publishDate),
			durationMs: this.DateParsers.parseTimespan(video.duration),
			thumbnail: {
				imageId: video.imageId,
				thumbnailUri: video.thumbnailUri
			},
			userTags: (video.userTags || []).map(({ userId: id, ...tag }) => ({ id, ...tag })),
			accessControlModel: {
				accessControl,
				accessControlEntities,
				password,
				enableExternalApplicationAccess,
				enableExternalViewersAccess
			},
			expiration: video.expiration?.expirationDate ? {
				...video.expiration,
				expirationDate: this.DateParsers.parseIsoDate(video.expiration.expirationDate)
			}
				: {},
			categories: categoryIds.map(id => categories.find(c => c.categoryId === id)),
			currentDateInAccountTimezone: this.DateParsers.parseIsoDate(video.currentDateInAccountTimezone),
			transcriptionFiles: settings.transcriptionFiles.map(file => ({
				...file,
				fileSise: file.filesSize || undefined,
				downloadUrl: file.downloadUrl || undefined
			})),
			lastViewed: this.DateParsers.parseIsoDate(video.lastViewed?.substring(0, 10)),
			whenUploaded: this.DateParsers.parseIsoDate(video.whenUploaded?.substring(0, 10)),
			viewerIdWatermarkText : video.viewerIdWatermarkText?.length ? video.viewerIdWatermarkText : features?.viewerIdSettings?.customInformation,
			aiMetadataGenerationStatus: {
				summary: video.aiMetadataGenerationStatus?.summary,
				title: video.aiMetadataGenerationStatus?.title,
				chapters: video.aiMetadataGenerationStatus?.chapters,
				description: video.aiMetadataGenerationStatus?.description,
				tags: video.aiMetadataGenerationStatus?.tags
			}
		};
	}

	public saveVideoSettings(video: IVideoSettings): Promise<void> {
		return lastValueFrom(this.videoSettings$.pipe(
			first(),
			switchMap(oldSettings => this.saveInternal(oldSettings, video))
		));
	}

	private saveInternal(
		oldSettings: IVideoSettings,
		video: IVideoSettings
	): Promise<void> {

		const oldIds = new Set(oldSettings.userTags.map(({ id }) => id));
		const taggedUserIds = new Set(video.userTags.map(user => user.id));
		const newTaggedUserIds = [...taggedUserIds].filter(id => !oldIds.has(id));
		const removedTaggedUserIds = [...oldIds].filter(id => !taggedUserIds.has(id));
		const command = this.getVideoSettingsCommand(video);
		if(!video.isActive && oldSettings.isActive) {
			command.expiration = {};
		}

		return this.tryUploadThumbnail(video.id, video.thumbnail)
			.then(() => this.uploadNewTranscriptions(video as IVideoSettings))
			.then(() => this.uploadNewSupplementalFiles(video as IVideoSettings))
			.then(() => this.PushService.dispatchCommand('media:SaveVideoSettings', {
				videoId: video.id,
				imageId: video.thumbnail.imageId,
				description: video.description,
				linkedUrl: video.linkedUrl,
				presentationProfileId: video.presentationProfileId,
				publishDate: formatIsoDate(video.publishDate),
				title: video.title,
				newTaggedUserIds,
				removedTaggedUserIds,
				supplementalContents: video.supplementalContents.map(file => ({
					id: file.id,
					title: file.title,
					filename: file.filename
				})),
				transcriptionFileIds: video.transcriptionFiles.map(({ id }) => id),
				ownerUserId: video.owner.id,
				...command
			}));
	}

	public applyTemplate(templateId: string, video: IVideoSettings, categories: ICategory[]): Promise<void> {
		return this.VideoTemplatesService.getTemplate(templateId)
			.then(({ metadata }) => {
				const hasContentCreatorRights = this.UserAuthorization.hasContentCreatorRights();
				const hasSetVideoPublicRights = this.SecurityContext.checkAuthorization('media.setVideoPublic');
				const isMediaAdmin = this.SecurityContext.checkAuthorization('admin.media');
				const isTemplateAccessControlApplicable = hasSetVideoPublicRights || metadata.accessControl !== AccessControl.Public;
				const isTemplateExternalApplicationAccessApplicable = this.MediaFeatures.accountFeatures.enableExternalApplicationsTrustedAccess && isMediaAdmin;
				const isTemplateExternalViewersAccessApplicable = this.MediaFeatures.accountFeatures.trustedAccessExternalViewersSettings?.isEnabled && hasSetVideoPublicRights;
				video.customFields.forEach(field => {
					const templateField = metadata.customFieldValues.find(f => f.id === field.id);
					if(templateField) {
						field.value = templateField.value;
					}
				});
				Object.assign(video, metadata, {
					templateId,
					isActive: metadata.isActive && video.approvalStatus === ApprovalStatus.APPROVED,
					categories: metadata.categoryIds.map(id => categories.find(c => c.categoryId === id)).filter(Boolean),
					expiration: {
						...metadata.expiration,
						expirationDate: this.getVideoExpiration(metadata.expiration.expiryRuleType, metadata.expiration.numberOfDays, video.lastViewed)
					},
					publishDate: !video.isPublished && metadata.isActive ?
						video.currentDateInAccountTimezone :
						video.publishDate,
					accessControlModel: {
						accessControl: isTemplateAccessControlApplicable ? metadata.accessControl : video.accessControlModel.accessControl,
						accessControlEntities: hasContentCreatorRights ?
							metadata.accessControlEntities :
							metadata.accessControlEntities.filter(entity => entity.type === AccessEntityType.Team),
						password: hasContentCreatorRights ? metadata.password : video.accessControlModel.password,
						enableExternalApplicationAccess: isTemplateExternalApplicationAccessApplicable ? !!metadata.enableExternalApplicationAccess : video.enableExternalApplicationAccess,
						enableExternalViewersAccess: isTemplateExternalViewersAccessApplicable ? !!metadata.enableExternalViewersAccess : video.enableExternalViewersAccess
					},
					sensitiveContent: metadata.sensitiveContent && video.controlSettings?.isFleEnabled ? metadata.sensitiveContent : video.sensitiveContent,
					enableAutoShowChapterImages: metadata.enableAutoShowChapterImages ?? true
				});
			});
	}

	public getVideoExpiration(expiryRuleType: RuleTypes, numberOfDays: number, lastViewed: Date): string {
		switch(expiryRuleType) {
			case RuleTypes.DAYS_AFTER_UPLOAD:
				return formatIsoDate(DateUtil.addDays(DateUtil.getToday(), numberOfDays));
			case RuleTypes.DAYS_WITHOUT_VIEW:
				return formatIsoDate(DateUtil.addDays(lastViewed, numberOfDays));
			case RuleTypes.NONE:
			default:
				return null;
		}
	}

	public saveVideoAsTemplate(name: string, video: IVideoSettings): Promise<void> {
		return this.PushService.dispatchCommand('media:AddVideoTemplate', {
			name,
			videoId: video.id,
			metadata: this.getVideoSettingsCommand(video, true)
		});
	}

	public isAuthorized(videoId: string): Promise<boolean> {
		return lastValueFrom(this.http.head<any>(`/media/videos/${videoId}`))
		// HEAD should return a null in response
			.then(resp => !resp)
			.catch(err => {
				console.error(err);
				return false;
			});
	}

	private getVideoSettingsCommand({ accessControlModel, ...video }: IVideoSettings, isTemplateCommand?: boolean): any {
		const features = this.MediaFeatures.accountFeatures;

		return {
			accessControl: accessControlModel.accessControl,
			accessControlEntities: [...accessControlModel.accessControlEntities.map(({ id, type, canEdit }) => ({ id, type, canEdit }))],
			enableExternalApplicationAccess: accessControlModel.enableExternalApplicationAccess,
			enableExternalViewersAccess: accessControlModel.enableExternalViewersAccess,
			categoryIds: !isTemplateCommand || features.enableCategories ? video.categories.map(c => c.categoryId) : [],
			commentsEnabled: video.commentsEnabled,
			customFieldValues: (video.customFields || []).map(({ id, value, type }) => ({ id, value, type })),
			downloadingEnabled: video.downloadingEnabled,
			enableAutoShowChapterImages: video.enableAutoShowChapterImages,
			isActive: video.isActive,
			password: accessControlModel.accessControl === AccessControl.Public ? accessControlModel.password : undefined,
			ratingsEnabled: video.ratingsEnabled,
			tags: !isTemplateCommand || features.enableTags ? video.tags : [],
			closedCaptionsEnabled: video.closedCaptionsEnabled,
			unlisted: video.unlisted,
			sensitiveContent: video.controlSettings?.isFleEnabled ? video.sensitiveContent : undefined,
			expiration: this.formatExpiration(video.expiration, isTemplateCommand),
			viewerIdEnabled: features?.viewerIdSettings ? VIEWER_ID_SETTING_HELPER[features.viewerIdSettings.viewerIdPolicy](video.viewerIdEnabled) : undefined,
			viewerIdWatermarkText : features.viewerIdSettings?.viewerIdPolicy == ViewerIdPolicy.DISABLE ? null :
									features.viewerIdSettings?.viewerIdPolicy == ViewerIdPolicy.REQUIRE ? features.viewerIdSettings.customInformation : video.viewerIdWatermarkText
		};
	}

	private formatExpiration(exp: IExpirationDetails, ruleOnly?: boolean): any {
		if(!exp?.expirationDate || ruleOnly && !exp.ruleId) {
			return {};
		}
		const expiration = {
			expirationDate: formatIsoDate(exp.expirationDate),
			deleteOnExpiration: exp.deleteOnExpiration
		};

		return exp.ruleId ? {
			...expiration,
			ruleId: exp.ruleId,
			expiryRuleType: exp.expiryRuleType,
			numberOfDays: exp.numberOfDays,
		} : expiration;
	}

	public tagUsers(videoId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:TagUsers', { videoId });
	}

	private tryUploadThumbnail(videoId: string, thumbnail: IVideoThumbnail): Promise<void> {
		if(thumbnail.imageId || !thumbnail.file && !thumbnail.formData) {
			return Promise.resolve();
		}

		return this.PushService.dispatchCommand('media:AddImageToVideo', { videoId }, 'ImageCreated')
			.then(result => {
				thumbnail.imageId = result.message.imageId;

				if (thumbnail.file) {
					thumbnail.file.setOptions({
						url: result.message.uploadUri
					});
					return thumbnail.file.submit();
				}

				return lastValueFrom(this.http.post(
					result.message.uploadUri,
					thumbnail.formData
				)) as any;
			});
	}

	private uploadNewSupplementalFiles(video: IVideoSettings): Promise<void> {
		//Uploads the files sequentially
		return video.supplementalContents.reduce(
			(promise, content) => promise.then(() =>
				(!content.id && content.file) ?
					this.VideoSupplementalFilesService.uploadSupplementalContent(content) :
					undefined
			),
			Promise.resolve());
	}

	private uploadNewTranscriptions(video: IVideoSettings): Promise<void> {
		//Uploads the files sequentially
		return video.transcriptionFiles.reduce(
			(promise, transcription) => promise.then(() =>
				(!transcription.id && transcription.file) ?
					this.addTranscriptionFile(video.id, transcription) :
					undefined
			),
			Promise.resolve());
	}

	private addTranscriptionFile(videoId: string, transcription: any): Promise<any> {
		return this.PushService
			.dispatchCommand('media:AddTranscriptionFile', {
				videoId,
				filename: transcription.filename,
				languageId: transcription.languageId
			}, 'TranscriptionFileAdded')
			.then(result => {
				transcription.file.setOptions({
					url: result.message.transcriptionFileUploadUri
				});
				transcription.id = result.message.transcriptionFileId;

				return transcription.file.submit();
			});
	}

	private subscribePush(videoId: string): Observable<any> {
		const status$ = merge(
			this.PushBus.getObservable(videoId, VIDEO_PLAYBACK_ROUTE, {
				VideoUploadingFailed: () => of({ status: VideoStatus.UPLOAD_FAILED }),
				VideoUploadingFinished: () => of({ status: VideoStatus.PROCESSING }),
				VideoProcessingFailed: data => of({ status: data.status }),
				VideoReplacing: () => of({ status: VideoStatus.UPLOADING }),
				VideoEditing: () => of({ status: VideoStatus.PROCESSING }),
				VideoTranscoded: () => of({ status: VideoStatus.READY }),
				VideoAnalyzed: data => of({ status: data.status }),
				VideoCopyFinished: () => of({ status: VideoStatus.READY })
			}),
			this.PushBus.getObservable(videoId, VideoSettingsRouteScope, {
				AudioTracksUpdated: data => of({ status: data.status })
			})
		);

		const videoSettings$ = this.PushBus.getObservable(videoId, VideoSettingsRouteScope, {
			TagUsersJobUpdated: of,
			VideoTranscoded: data => of({
				isProcessing: false,
				facialInstanceSizeError: data.facialInstanceSizeError,
				hasFacialRecognitionInstance: data.hasFacialRecognitionInstance,
				hasTranscriptionInstance: data.hasTranscriptionInstance,
				transcriptionInstanceTooLarge: data.transcriptionInstanceTooLarge
			}),
			VideoCopyFinished: data => of({
				isProcessing: false,
				facialInstanceSizeError: data.facialInstanceSizeError,
				hasFacialRecognitionInstance: data.hasFacialRecognitionInstance,
				hasTranscriptionInstance: data.hasTranscriptionInstance,
				transcriptionInstanceTooLarge: data.transcriptionInstanceTooLarge,
				instances: data.instances
			}),
			AudioTracksUpdating: data => of({
				isProcessing: true
			}),
			AudioTracksUpdated: data => of({
				isProcessing: false,
				facialInstanceSizeError: data.facialInstanceSizeError,
				hasFacialRecognitionInstance: data.hasFacialRecognitionInstance,
				hasTranscriptionInstance: data.hasTranscriptionInstance,
				transcriptionInstanceTooLarge: data.transcriptionInstanceTooLarge
			})
		});

		return merge(status$, videoSettings$)
			.pipe(map(e => e.data));
	}
}
