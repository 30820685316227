import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { UnlistedVideoIndicatorAngularModule } from 'rev-shared/media/unlistedVideoIndicator/UnlistedVideoIndicator.AngularModule';

import { VideoTextStatusComponent } from './VideoTextStatus.Component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

const entryComponents = [VideoTextStatusComponent];
@NgModule({
	declarations: entryComponents,
	exports: entryComponents,
	imports: [
		CommonModule,
		TooltipModule,
		TranslateModule,
		UnlistedVideoIndicatorAngularModule
	]
})
export class VideoTextStatusAngularModule {}
