import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { FlickityCarouselModule } from 'rev-shared/ui/flickityCarousel/FlickityCarousel.AngularModule';

import { VideoPlaylistCarousel } from './VideoPlaylistCarousel.Component';

const components = [VideoPlaylistCarousel];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		DateAngularModule,
		FlickityCarouselModule,
		TooltipModule,
		TranslateModule
	]
})
export class VideoPlaylistModule {}
