import { Component, Input } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { StateService } from '@uirouter/angular';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaylistContextService } from 'rev-shared/media/PlaylistContext.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { PlaylistType } from 'rev-shared/media/IPlaylist';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';
import { PLAYLIST_DETAIL_STATE } from 'rev-shared/bootstrap/AppStateNames';

import styles from './NewPlaylistDialog.module.less';
import { SearchFilterStateService, getFilterValues } from '../SearchFilterState.Service';

@Component({
	selector: 'new-playlist-dialog',
	templateUrl: './NewPlaylistDialog.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class NewPlaylistDialogComponent {
	@Input() public playlistType: PlaylistType;
	@Input() public searchQuery: string;

	public readonly styles = styles;
	public readonly PlaylistType = PlaylistType;

	public playlistName: string;
	public selectedPlaylistType = PlaylistType.Static;

	constructor(
		private readonly bsModalRef: BsModalRef,
		private readonly PlaylistService: PlaylistService,
		private readonly PlaylistContext: PlaylistContextService,
		private readonly UserContext: UserContextService,
		private readonly SearchFilterState: SearchFilterStateService,
		private readonly MediaStateService: MediaStateService,
		public readonly MediaFeaturesService: MediaFeaturesService,
		private readonly $state: StateService
	) {}

	public hideDialog() {
		this.bsModalRef.hide();
	}

	public createPlaylist() {
		const playlistType = this.playlistType || this.selectedPlaylistType;
		this.PlaylistService.createPlaylist({
			name: this.playlistName,
			userId: this.UserContext.getUser().id,
			playlistType,
			searchFilter: playlistType !== PlaylistType.Dynamic ? null : {
				searchTerm: this.searchQuery,
				sortDescending: this.searchQuery ? this.MediaStateService.getIsSortDesc() : true,
				sortField: this.searchQuery ? this.MediaStateService.getSortField() : this.MediaFeaturesService.accountFeatures.defaultVideoSortOrder,
				...this.SearchFilterState.getQueryValues() as any
			}
		})
			.then(playlistId => {
				this.$state.go(PLAYLIST_DETAIL_STATE, { playlistId }, { inherit: false });
				this.PlaylistContext.setEditMode(true);
			})
			.catch(e => {
				console.error('Error Saving Playlist: ', e);
			})
			.finally(() => {
				this.hideDialog();
			});
	}
}
