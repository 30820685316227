import { Component, Input } from '@angular/core';

import { ICategory } from 'rev-shared/media/Media.Contract';

import styles from './CategoryTile.module.less';

@Component({
	selector: 'category-tile',
	templateUrl: './CategoryTile.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class CategoryTileComponent {
	@Input() public category: ICategory;

	public readonly styles = styles;
}
