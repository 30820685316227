import { Component } from '@angular/core';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { MainHeaderComponent } from './MainHeader.Component';

@Component({
	selector: 'twotier-header',
	templateUrl: './TwoTierHeader.Component.html'
})
export class TwoTierHeader extends MainHeaderComponent {

	public themeStyleOverrides: IRules;

	public ngOnInit(): void {
		if (this.UserContext.isUserAuthenticated()) {
			this.loadUpcomingEvents();
		}
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings));
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	public loadUpcomingEvents(): void {
		this.ScheduledEventService.getUpcomingEvents(this.accountId)
			.then(events => this.event = events[0]);
	}

	public applyCssRules(brandingSettings: BrandingSettings): void {
		const themeAccentColor = brandingSettings.themeSettings.accentColor;
		const themeAccentFontColor = brandingSettings.themeSettings.accentFontColor;
		const headerFontColor = brandingSettings.headerSettings.fontColor;
		const headerBackgroundColor = brandingSettings.headerSettings.backgroundColor;
		this.themeStyleOverrides = {
			'.theme-TwoTier .open .dropdown-toggle:before': {
				'border-bottom-color': `${themeAccentColor}`
			},
			[`
				.theme-TwoTier .main-nav-btn:not(:hover):not(.theme-accent-bg) .glyphicons,
				.theme-TwoTier .main-nav-btn:not(:hover):not(.theme-accent-bg) .vb-icon,
				.twoTier-search-header vb-ui-typeahead-angular input
			`]: {
				'color': `${headerFontColor}`
			},
			'.theme-TwoTier search-header vb-ui-typeahead-angular': {
				'border-bottom': `1px solid ${headerFontColor}`
			},
			[`
				.theme-TwoTier .main-nav-btn:not(.btn-mobile):hover .glyphicons,
				.theme-TwoTier .main-nav-btn:not(.btn-mobile):hover .vb-icon,
				.theme-TwoTier .dropdown.open .main-nav-btn:not(.theme-accent-btn):not(.btn-mobile-open) .glyphicons,
				.theme-TwoTier .dropdown.open .main-nav-btn:not(.theme-accent-btn):not(.btn-mobile-open) .vb-icon
			`]: {
				'color': `${headerFontColor} !important`
			},
			[`
				.theme-TwoTier notifications-menu-button .notifications.open .glyphicons,
				.theme-TwoTier go-live .go-live.open .vb-icon,
				.theme-TwoTier help-menu .help-nav .help-nav-link:hover .new_window_alt
			`]: {
				'color': `${headerFontColor}`
			},
			'.theme-TwoTier notifications-menu-button .notifications-count': {
				'background': `${themeAccentColor}`,
				'color': `${themeAccentFontColor}`,
				'border-color': `${headerFontColor} !important`
			},
			'.theme-TwoTier help-menu .open .dropdown-toggle:before': {
				'bottom': '-20px',
				'margin-left': '5px !important',
				'left': 'auto !important'
			},
			'main-header > header.theme-TwoTier': {
				'border-bottom-color': `${headerBackgroundColor}`,
				'border-bottom-width': '2px',
				'border-bottom-style': 'solid'
			},
			'.theme-TwoTier help-menu .dropdown-toggle:after': {
				'background-color': 'transparent !important'
			}
		};
	}
}
