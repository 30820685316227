import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { ITemplate, ITemplateMenuService } from 'rev-shared/ui/templates/TemplateMenu.Component';

@Injectable({
	providedIn: 'root'
})
export class VideoTemplatesService implements ITemplateMenuService {

	constructor(
		private http: HttpClient,
		private UserContext: UserContextService
	) {}

	public getTemplates(accountId?: string): Promise<ITemplate[]> {
		accountId = accountId || this.UserContext.getAccount().id;

		return lastValueFrom(this.http.get<any>(`/media/videos/accounts/${accountId}/templates`))
			.then(result => result.templates);
	}

	public getTemplate(id: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/videos/templates/${id}`));
	}
}
