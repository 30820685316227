import { Component, Input, DoCheck, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { getBaseState } from 'rev-portal/media/MediaStateDeclarations';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { IPlaylistData, PlaylistType } from 'rev-shared/media/IPlaylist';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaylistContextService } from 'rev-shared/media/PlaylistContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { isPortal } from 'rev-shared/util/AppUtil.Service';
import { MY_PLAYLIST_STATE, PLAYLIST_DETAIL_STATE, FEATURED_PLAYLIST_DETAIL_STATE } from 'rev-shared/bootstrap/AppStateNames';

import { MediaStateService, ICategoryPath, ISearchResultsState, MediaViewMode, VIDEO_SELECTION_LIMIT, SortField } from 'rev-portal/media/MediaState.Service';

import styles from './mediaSearchToolbar.module.less';

@Component({
	selector: 'vb-media-toolbar',
	templateUrl: './VbMediaToolbar.Component.html',
	host: {
		'[class]': 'styles.mediaToolbar'
	}
})
export class VbMediaToolbarComponent implements DoCheck, OnInit {
	@Input() public approvalProcessTemplateCount: number;
	@Input() public team: any;
	@Input() public pendingVideoCount: number;

	public baseCategoryId: string;
	public baseCategoryIndex: number;
	public category: ICategoryPath;
	public categoryPath: ICategoryPath[];
	public searchResultsState: ISearchResultsState;
	public isAuthenticated: boolean;

	public readonly appStates = {
		playlistDetails: PLAYLIST_DETAIL_STATE,
		featuredPlaylist: FEATURED_PLAYLIST_DETAIL_STATE,
		myPlaylist: MY_PLAYLIST_STATE
	};

	public get playlist(): IPlaylistData {
		return this.PlaylistContext.playlist() || {} as any;
	}

	public readonly styles = styles;
	public readonly videoSelectionLimit = VIDEO_SELECTION_LIMIT;
	public readonly isPortal = isPortal();

	public readonly mediaLabels = {
		playlist: ['Media_OnePlaylist', 'Media_NumPlaylists'],
		video: ['Media_OneVideo', 'Media_NumVideos']
	};

	constructor(
		public readonly $state: StateService,
		public readonly DialogService: DialogService,
		public readonly MediaFeaturesService: MediaFeaturesService,
		public readonly MediaStateService: MediaStateService,
		public readonly PlaylistContext: PlaylistContextService,
		public readonly UIRouterGlobals: UIRouterGlobals,
		public readonly UserContext: UserContextService,
		private readonly LoginRedirectService: LoginRedirectService
	)
	{}

	public ngOnInit(): void {
		this.isAuthenticated = this.UserContext.isUserAuthenticated();
	}

	public ngDoCheck(): void {
		if(this.searchResultsState !== this.MediaStateService.searchResultsState ||
			this.baseCategoryId !== this.MediaStateService.baseCategoryId) {
			this.updateMediaState();
		}
	}

	public get showPendingApproval(): boolean {
		const state = this.baseStateName;
		return !this.team &&
			this.approvalProcessTemplateCount > 0 && (
			state === 'portal.media.all' ||
			state === 'portal.media.uploads'
		);
	}

	public get baseStateName(): string {
		return getBaseState(this.UIRouterGlobals);
	}

	public get selectedCount(): number {
		return this.searchResultsState.getSelectedCount?.() ?? 0;
	}

	public updateMediaState(): void {
		const { baseCategoryId, searchResultsState } = this.MediaStateService;
		this.searchResultsState = searchResultsState || {};
		this.categoryPath = searchResultsState.categoryPath;

		if(this.categoryPath) {
			this.baseCategoryIndex = baseCategoryId && this.categoryPath.findIndex(c => c.id === baseCategoryId);
			this.category = this.categoryPath[this.categoryPath.length-1];
		}
		else {
			this.baseCategoryIndex = undefined;
			this.category = undefined;
		}
	}

	public onSignInClick(): void {
		const loginLink: string = this.LoginRedirectService.getLoginLink();

		if (loginLink?.length) {
			const separator = loginLink.includes('?') ? '&': '?';
			window.location.href = `${loginLink}${separator}initiateLogin=true`;
		}
	}

	public setViewMode(viewMode: MediaViewMode): void {
		this.MediaStateService.setViewMode(viewMode);

		if (viewMode === MediaViewMode.TILES && !this.MediaStateService.isValidTileSort()) {
			this.MediaStateService.showSortRecommended ?
				this.MediaStateService.setSortField(SortField.Recommended) :
				this.MediaStateService.setSortField(SortField.Title);
			this.MediaStateService.setDefaultSortDirection();
		}
		this.$state.go('.', this.MediaStateService.getQueryParams(), { reload: true });
	}

	public enableEditMode(): void {
		this.PlaylistContext.setEditMode(true);
	}

	public cancelEditMode(): void {
		this.PlaylistContext.setEditMode(false);
	}

	public renamePlaylist(name): void {
		this.PlaylistContext.modifyPlaylist({
			name
		})
			.catch(e => console.log('Rename Playlist Error: ', e))
			.finally(() => {
				this.PlaylistContext.setEditMode(false);
			});
	}

	public openNewPlaylistDialog(): void {
		this.DialogService.getDialog('NewPlaylistDialog').open({
			initialState: {
				playlistType: this.baseStateName === 'portal.media.search' ? PlaylistType.Dynamic : undefined,
				searchQuery: this.searchResultsState.searchQuery
			}
		});
	}
}
