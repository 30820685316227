import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { ParamTypes, Transition } from '@uirouter/angular';

import { DownloadDetailsComponent } from './DownloadDetails.Component';
import { EntryPointStartup, IEntryPointStartup } from 'rev-shared/entryPoint/EntryPointStartup.Provider';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

export const states: IVbNg2StateDeclaration[] = [
	{
		name: 'download-details',
		url: '/downloads/:id?downloadUrl',
		component: DownloadDetailsComponent,
		authorizationKey: 'downloads',
		params: {
			downloadUrl: { value: null, squash: true, type: ParamTypes.string, dynamic: true }
		},
		resolve: [
			{
				token: 'init',
				deps: [EntryPointStartup],
				resolveFn: (EntryPointStartup: IEntryPointStartup) => EntryPointStartup.$promise
			},
			{
				token: 'accountId',
				deps: [UserContextService],
				resolveFn: (UserContext: UserContextService) => UserContext.getAccount().id
			},
			{
				token: 'fileId',
				deps: [Transition],
				resolveFn: (t: Transition) => t.params().id
			},
			{
				token: 'fileDownloadUrl',
				deps: [Transition],
				resolveFn: (t: Transition) => t.params().downloadUrl
			}
		]
	}
];
