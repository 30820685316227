<section [ngClass]="styles.columnContainer" ngForm="vbUserForm" #vbUserForm="ngForm">
	<ng-container *ngIf="value">
		<section class="theme-primary-border" flex="50" [ngClass]="styles.columnLeft">

			<vb-ui-form-group>
				<label #uiLabel>
					{{ value.username }}
				</label>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="firstName" #uiLabel>
					{{ 'FirstName' | translate }}
				</label>
				<input type="text" name="firstName" id="firstName" [(ngModel)]="value.firstName" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="lastName" #uiLabel>
					{{ 'LastName' | translate }}
				</label>
				<input #lastName="ngModel" type="text" name="lastName" id="lastName" vbRequired [(ngModel)]="value.lastName" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
				<div uiValidation>
					<div *ngIf="lastName.invalid" role="alert" class="margin-bottom-10" vbUiErrorMessage>
						<ng-container *ngIf="lastName.errors.required">
							{{ 'ThisFieldIsRequired' | translate }}
						</ng-container>
					</div>
				</div>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="email" #uiLabel>
					{{ 'ContactEmail' | translate }}
				</label>
				<input #email="ngModel" type="text" name="email" id="email" [(ngModel)]="value.email" [disabled]="isLdapUser" [vbClearOnChange]="'emailInUse'" vbEmail uiControl vbUiTextInput trim="blur"/>
				<div uiValidation>
					<div *ngIf="email.invalid" role="alert" class="margin-bottom-10" vbUiErrorMessage>
						<div *ngIf="email.errors.emailInUse">
							{{ 'EmailInUse' | translate }}
						</div>
						<div *ngIf="email.errors.vbEmail">
							{{ 'InvalidEmailError' | translate }}
						</div>
					</div>
				</div>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="profileTitle" #uiLabel>
					{{ 'ContactInfo_Title' | translate }}
				</label>
				<input type="text" name="title" id="profileTitle" [(ngModel)]="value.title" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="phone" #uiLabel>
					{{ 'PhoneNumber' | translate }}
				</label>
				<input type="text" name="phone" id="phone" [(ngModel)]="value.phone" [disabled]="isLdapUser" uiControl vbUiTextInput trim="blur"/>
			</vb-ui-form-group>

			<vb-ui-form-group control-name="'language'">
				<label for="language" #uiLabel>
					{{ 'PreferredLanguage' | translate }}
				</label>
				<vb-ui-select uiControl>
					<select #selectElement [(ngModel)]="value.language" id="language" name="language">
						<option value="">{{ 'None' | translate }}</option>
						<option *ngFor="let language of languageCodes" [value]="language.code">
							{{ language.name }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

		</section>

		<!-- Right Hand Section -->
		<section flex="50" [ngClass]="styles.columnRight">
			<ng-container *ngIf="value.isProfileImageProcessing; else imgSelector">
				<vb-ui-form-group>
					<label for="language" #uiLabel>
						{{ 'UserProfile_ProfilePicture' | translate }}
					</label>
					<div uiControl [ngClass]="styles.profileInProgress">
						<vb-loading-spinner uiControl [size]="'small'">
							</vb-loading-spinner>
						<alert type="warning" class="width-full">
							{{ 'UserProfile_ProfilePictureUploadInProgress' | translate }}
						</alert>
					</div>
				</vb-ui-form-group>
			</ng-container>
			<ng-template #imgSelector>
				<image-selector #imageModel="ngModel" name="profileImage" [ngClass]="styles.profilePicture" (ngModelChange)="onImageChanged($event)" [imageComponentMetadata]="imageCfgBasic" [(ngModel)]="imageDataBasic" [disabled]="imageCfgBasic.readOnly" [label]="'UserProfile_ProfilePicture' | translate">
				</image-selector>
			</ng-template>

			<vb-ui-form-group control-name="'disableFacialRecognition'" *ngIf="showFacialRecognitionOptOut()">
				<vb-ui-checkbox name="disableFacialRecognition" [(ngModel)]="value.disableFacialRecognition" uiControl>
					{{ 'UserProfile_DisableFacialRecognition' | translate }}
				</vb-ui-checkbox>
			</vb-ui-form-group>

		</section>
	</ng-container>
</section>
