import { Component } from '@angular/core';

import { VideoSearchResultsComponent } from './VideoSearchResults.Component';

import styles from './VideoSearchResultsTiles.module.less';

@Component({
	selector: 'video-search-results-tiles',
	templateUrl: './VideoSearchResultsTiles.Component.html'

})
export class VideoSearchResultsTilesComponent {
	public readonly styles = styles;

	constructor(
		public videoSearchResults: VideoSearchResultsComponent
	) {}
}
