import { Injectable } from '@angular/core';

import { ICommandPromise, PushService } from 'rev-shared/push/PushService';

@Injectable({
	providedIn: 'root'
})
export class BulkEditService {

	constructor(
		private PushService: PushService
	) { }

	public deleteVideos(videoIds: string[]): ICommandPromise<any> {
		return this.PushService.dispatchCommand('media:DeleteVideos', { videoIds }, 'VideosDeleting');
	}

	public updateVideos(videoIds: string[], operations: any[]): ICommandPromise<any> {
		operations.forEach(op => op.fieldName = op.fieldName.charAt(0).toUpperCase() + op.fieldName.substr(1));

		return this.PushService.dispatchCommand('media:UpdateVideos', { videoIds, operations }, 'VideosUpdating');
	}

	public generateVideoMetadata(videoIds: string[], transcription: any, metadataGenerationFields: any[]): ICommandPromise<any> {
		return this.PushService.dispatchCommand('media:BulkGenerateVideoMetadata', { videoIds, transcription, metadataGenerationFields }, 'BulkGeneratingVideoMetadata');
	}
}
