<div class="tile-item" [ngClass]="styles.tileItem">
	<a uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">

		<div class="fixed-ratio-wrapper grid-item-list">
			<div *ngIf="category.thumbnailImageUri; else noImage;" class="fixed-ratio">
				<div class="image-wrapper">
					<div class="fixed-ratio-wrapper">
						<div [ngClass]="styles.imageContainer" class="fixed-ratio">
							<img [src]="category.thumbnailImageUri" loading="lazy"/>
						</div>
					</div>
				</div>
			</div>
			<ng-template #noImage>
				<div class="fixed-ratio">
					<span class="vb-icon vb-icon-folder-play"></span>
				</div>
			</ng-template>
		</div>

		<div class="tile-footer" [ngClass]="styles.tileFooter">
			<div [ngClass]="styles.title">
				{{category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name}}
			</div>
		</div>

	</a>

	<div class="tile-footer" [ngClass]="styles.tileFooter" layout="row">
		<a flex="75" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }" [ngPlural]="category.videoCount || 0">
			<ng-template ngPluralCase="=1">{{ 'Media_OneVideo' | translate }}</ng-template>
			<ng-template ngPluralCase="other">{{ 'Media_NumVideos' | translate: { '0': (category.videoCount | number) } }}</ng-template>
		</a>

		<div display="flex" flex="25" flex-justify="end">
			<a *ngIf="category.canEdit" class="margin-right-5" uiSref="portal.edit-category" [uiParams]="{ categoryId: category.id }" [attr.aria-label]="'EditCategory' | translate">
				<span class="glyphicons cogwheel"></span>
			</a>

			<a *ngIf="category.restricted" class="glyphicons lock margin-right-5 vb-icon-center" uiSref="portal.media.category-detail" [uiParams]="{categoryId: category.id}" [attr.aria-label]="'Media_Category_GoToCategory' | translate : { '0': category.isUncategorized ? ('Media_Uncategorized' | translate) : category.name }">
			</a>
		</div>
	</div>

</div>
