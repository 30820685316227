import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

import './SpeechSearchResultsList.less';

@Component({
	selector: 'speech-search-results-list',
	templateUrl: './SpeechSearchResultsList.Component.html'
})
export class SpeechSearchResultsListComponent implements OnInit {
	@Input() public query: string;
	@Input() public results: any[];
	@Input() public videoId: string;
	@Input() public maxShownByDefault: number;
	@Input() public resultExpanded: boolean;

	@Output() public searchTranscriptClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

	public shownResults: any[];

	public ngOnInit(): void {
		this.filterResults();
	}

	public onSearchMoreTranscript(): void {
		this.searchTranscriptClicked.emit();
	}

	private filterResults(): void {
		this.shownResults = this.results.slice(0, this.maxShownByDefault);
	}
}
