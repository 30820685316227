import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ThumbnailSheetPreviewModule } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetPreview.Module';
import { UnlistedVideoIndicatorAngularModule } from 'rev-shared/media/unlistedVideoIndicator/UnlistedVideoIndicator.AngularModule';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';

import { VideoThumbnailStatusAngularModule } from 'rev-portal/media/videos/videoThumbnailStatus/VideoThumbnailStatus.AngularModule';

import { VideoTitleCellRendererComponent } from './VideoTitleCellRenderer.Component';
import { VbMediaTableSelectColumnHeaderAngularComponent } from './VbMediaTableSelectColumnHeaderAngular.Component';
import { VbMediaTableColumnHeaderAngularComponent } from './VbMediaTableColumnHeaderAngular.Component';
import { VbMediaTableColumnAngularComponent } from './VbMediaTableColumnAngular.Component';
import { VbMediaTableAngularComponent } from './VbMediaTableAngular.Component';
import { VbMediaGridWrapperComponent } from './VbMediaGridWrapper.Component';
import { VbMediaGridThumbnailCellRendererComponent } from './VbMediaGridThumbnailCellRenderer.Component';

const exportComponents = [
	VbMediaGridThumbnailCellRendererComponent,
	VbMediaGridWrapperComponent,
	VbMediaTableAngularComponent,
	VbMediaTableColumnAngularComponent,
	VbMediaTableColumnHeaderAngularComponent,
	VbMediaTableSelectColumnHeaderAngularComponent,
	VideoTitleCellRendererComponent
];

@NgModule({
	imports: [
		CommonModule,
		ThumbnailSheetPreviewModule,
		TranslateModule,
		UIRouterModule,
		UnlistedVideoIndicatorAngularModule,
		Video360IndicatorModule,
		VideoThumbnailStatusAngularModule
	],
	declarations: exportComponents,
	exports: exportComponents,
})
export class VbMediaTableAngularModule { }
