import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { StateService } from '@uirouter/angular';

import { ISearchFilters, SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';
import { ICategorySettings } from 'rev-shared/ui/categorySettings/ICategorySettings';
import { InsightSearchHelper } from 'rev-portal/search/InsightSearchHelper.Service';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { NONE } from 'rev-shared/media/MediaConstants';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { TABLE_DATA_MODE } from './tableLayout/Contract';

@Component({
	selector: 'search-filter-form',
	templateUrl: './SearchFilterForm.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SearchFilterFormComponent),
		multi: true
	}]
})

export class SearchFilterFormComponent extends BaseControlValueAccessor<ISearchFilters> implements OnInit {
	@Input() public categories: ICategorySettings[];
	@Input() public isGuestUser: boolean;
	@Input() public tableDataMode: TABLE_DATA_MODE;
	@Input() public teams: any[];
	@Input() public isClearVisible: boolean;

	public isCategoryFilterEnabled: boolean;
	public isUnlistVideoEnabled: boolean;
	public ownerInsightOptions: any;
	public uploaderInsightOptions: any;
	public userTagsInsightOptions: any;
	public teamsWhitelist: any[] = [];

	constructor(
		private InsightSearchHelper: InsightSearchHelper,
		private MediaFeatures: MediaFeaturesService,
		private SearchFilterState: SearchFilterStateService,
		private UserContextService: UserContextService,
		private $state: StateService
	) {
		super();
	}

	public ngOnInit(): void {
		const accountId = this.UserContextService.getAccount().id;
		this.isCategoryFilterEnabled = this.MediaFeatures.accountFeatures.enableCategories && this.isCategoryFilterApplicable;
		this.isUnlistVideoEnabled = this.MediaFeatures.accountFeatures.enableUnlisted;

		this.uploaderInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: accountId,
			users: true
		});

		this.ownerInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: accountId,
			users: true
		});

		this.userTagsInsightOptions = this.InsightSearchHelper.buildAccessEntityInsightOptions({
			accountId: accountId,
			users: true
		});
	}

	public clear(): void {
		this.SearchFilterState.go({}, this.$state);
	}

	public onTeamTagsChanged(): void {
		this.updateTeamsWhitelist();
		this.onChangeInternal();
	}

	public onChangeInternal() {
		this.writeValue(this.value);
		this.onChangeFn(this.value);
	}

	public onUploadDateChange(date: any ): void {
		this.value.whenUploaded.value = {};
	}

	private get isCategoryFilterApplicable() : boolean {
		return this.tableDataMode !== TABLE_DATA_MODE.CategoryAndVideo;
	}

	public override writeValue(val: ISearchFilters): void {
		if(!val) {
			return;
		}

		super.writeValue(val);
		this.updateTeamsWhitelist();
	}

	private updateTeamsWhitelist(): void {
		const teamNone = { name: NONE, teamId: '' };

		if(this.value.teamIds.value.length) {
			this.teamsWhitelist = this.value.teamIds.value.some(team => team.name === NONE)
				? [teamNone]
				: this.teams;
			return;
		}

		this.teamsWhitelist = [teamNone, ...this.teams];
	}
}
