<div [ngClass]="styles.tilesContainer" [hidden]="!(videoSearchResults.status.active)">

	<ng-container *ngFor="let category of videoSearchResults.categories">
		<category-tile *ngIf="category.canEdit || category.videoCount" [ngClass]="styles.tile" [category]="category" [thumbnails]="category.thumbnails">
		</category-tile>
	</ng-container>

	<category-tile *ngIf="videoSearchResults.uncategorizedCatEntry" [ngClass]="styles.tile" [category]="videoSearchResults.uncategorizedCatEntry" [thumbnails]="videoSearchResults.uncategorizedCatEntry.thumbnails">
	</category-tile>

	<video-tile *ngFor="let video of videoSearchResults.videos" [ngClass]="styles.tile" [isResponsiveTile]="false" [video]="video" [config]="videoSearchResults.videoPlaybackConfig" [mediaFeatures]="videoSearchResults.mediaFeatures" [hasMediaEditAuth]="videoSearchResults.hasMediaEditAuth" [userId]="videoSearchResults.userId" [isGuest]="videoSearchResults.isGuest">
	</video-tile>

</div>