<vb-media-teams-header *ngIf="team?.id" [team]="team">
</vb-media-teams-header>

<div class="container-fluid flex-navigation-bar-wrap margin-top-10" [ngClass]="styles.mediaToolbarWrapper">
	<div [ngClass]="styles.mediaToolbarContainer">

		<div [ngClass]="styles.mediaToolbarNav" [ngSwitch]="baseStateName">

			<span class="type-bold" *ngSwitchCase="'portal.media.all'">{{ 'Media_AllVideos' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.browse'">{{ 'Media_BrowseCategories' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="appStates.myPlaylist">{{ 'Media_Menu_Playlists' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.uploads'">{{ 'Media_UserVideos' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.expirations'">{{ 'Media_Expirations' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.pending-videos'">{{ 'PendingApproval' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.edit'">{{ 'Media_BulkEdit' | translate }}</span>
			<span class="type-bold" *ngSwitchCase="'portal.media.my-subscriptions'">{{ 'Media_MySubscriptions' | translate }}</span>

			<span *ngSwitchCase="'portal.media.category-detail'">
				<span *ngIf="MediaStateService.baseCategoryId; else browseCategoriesLink" class="type-bold theme-primary-txt">
					{{ 'Media_BrowseCategories' | translate }}
				</span>
				<ng-template #browseCategoriesLink>
					<a class="type-bold theme-primary-txt" uiSref="portal.media.browse">
						{{ 'Media_BrowseCategories' | translate }}
					</a>
				</ng-template>
				<span *ngFor="let path of categoryPath; let $index = index; let $last = last" [ngClass]="{ 'd-none d-md-inline': !$last, active: $last }">
					<span class="glyphicons chevron-right"></span>
					<span *ngIf="$last || $index < baseCategoryIndex; else pathLink">
						{{ path.name || 'Media_Uncategorized' | translate }}
					</span>
					<ng-template #pathLink>
						<a class="theme-primary-txt" uiSref="." [uiParams]="{ categoryId: path.id }" [uiOptions]="{ reload: true, relative: $state.current.name }">
							{{path.name }}
						</a>
					</ng-template>
				</span>
			</span>

			<span *ngSwitchCase="appStates.featuredPlaylist">
				<a [ngClass]="styles.toolbarAnchor" class="type-bold theme-primary-txt" [uiSref]="appStates.myPlaylist">
					{{ 'Media_Menu_Playlists' | translate }}
				</a>
				<span class="glyphicons chevron-right"></span>
				{{ 'Media_Videos_Playlists_FeaturedVideos' | translate }}
			</span>

			<span *ngSwitchCase="appStates.playlistDetails">
				<a [ngClass]="styles.toolbarAnchor" class="type-bold theme-primary-txt" [uiSref]="appStates.myPlaylist">
					{{ 'Media_Menu_Playlists' | translate }}
				</a>
				<span class="glyphicons chevron-right"></span>
				<span>
					<a *ngIf="!PlaylistContext.isEditMode() || playlist.playlistType === 'Dynamic'" [ngClass]="styles.toolbarAnchor" class="theme-primary-txt margin-left-10 util-underline" (click)="cancelEditMode()">
						{{ playlist.name }}
					</a>
					<a [ngClass]="styles.toolbarAnchor" class="theme-primary-txt margin-left-10 util-underline" (click)="enableEditMode()" *ngIf="playlist.canEdit
							&& playlist.playlistType === 'Static'
							&& !PlaylistContext.isEditMode()">
						<small>{{ 'Media_Playlists_Edit' | translate }}</small>
					</a>
				</span>
				<vb-ui-inline-text-edit class="box-inline-block" [required]="true" [value]="playlist.name" *ngIf="PlaylistContext.isEditMode() && playlist.playlistType === 'Static'" (onSubmit)="renamePlaylist($event)" (onCancel)="cancelEditMode()">
				</vb-ui-inline-text-edit>
				<span *ngIf="PlaylistContext.isEditMode()">
					<span class="glyphicons chevron-right"></span>
					{{ 'Editing' | translate }}
				</span>
			</span>

			<span *ngSwitchCase="'portal.media.search'">
				<span class="type-bold">
					{{ 'Media_Videos_SearchResults' | translate }}
				</span>
				<span class="glyphicons chevron-right"></span>
				{{ searchResultsState.searchQuery }}
			</span>

			<span *ngIf="searchResultsState.mediaType" class="type-bold type-14 padding-left-5" [ngPlural]="searchResultsState.mediaCount">
				<ng-template ngPluralCase="=1">
					({{ mediaLabels[searchResultsState.mediaType][0] | translate }})
				</ng-template>
				<ng-template ngPluralCase="other">
					({{ mediaLabels[searchResultsState.mediaType][1] | translate : { '0': searchResultsState.mediaCount } }})
				</ng-template>
				<ng-container *ngIf="selectedCount">
					({{ 'Media_BulkEdit_SelectionCount' | translate : { '0': selectedCount | number } }})
				</ng-container>
			</span>
			<div *ngIf="selectedCount > videoSelectionLimit" class="text-align-left" role="alert" vbUiErrorMessage>
				({{ 'Media_BulkEdit_SelectionLimitMessage' | translate: { '0': videoSelectionLimit | number } }})
			</div>
		</div>

		<div [ngClass]="styles.mediaToolbarControls">

			<div [ngClass]="styles.mediaToolbarControlBtns">

				<ng-container *ngIf="baseStateName === 'portal.media.my-subscriptions' &&
					(MediaFeaturesService.accountFeatures?.enableContentNotifications || MediaFeaturesService.accountFeatures?.enableSpark)">
					<button type="button" class="margin-right-10" [uiSref]="'portal.manageSubscriptions'" vbBtnPrimary>
						<span class="glyphicons bell theme-accent-txt"></span>
						{{ 'ManageSubscriptions' | translate }}
					</button>
				</ng-container>

				<sign-in-btn class="margin-right-10" *ngIf="MediaStateService.showSignInBtn" (onClick)="onSignInClick()">
				</sign-in-btn>

				<vb-user-subscriptions *ngIf="team || category" class="margin-right-10" [category]="category" [team]="team">
				</vb-user-subscriptions>

				<a *ngIf="showPendingApproval" role="button" class="margin-right-10" uiSref="portal.media.pending-videos" uiSrefActive="active" vbBtnPrimary>
					<span>
						<span class="text-label">{{ 'PendingApproval' | translate }}</span>
						@if (pendingVideoCount) { <span class="badge badge-pending ms-1 d-none d-md-inline">{{ pendingVideoCount }}</span> }
					</span>
				</a>

				<button type="button" class="margin-right-10" *ngIf="!PlaylistContext.isEditMode() &&
						playlist?.canEdit &&
						playlist.playlistType === 'Dynamic'" vbBtnPrimary (click)="enableEditMode()">
					{{ 'Media_Playlists_EditPlaylist' | translate }}
				</button>

				<button type="button" *ngIf="!PlaylistContext.isEditMode() && playlist.videos" class="margin-right-10" uiSref="portal.video-playlist-playback" [uiParams]="{ videoId: playlist.videos[0]?.id, playlistId: playlist.id }" [disabled]="playlist.videos.length === 0" [attr.aria-label]="'PlayAll' | translate" vbBtnPrimary>
					<span class="glyphicons play" [ngClass]="styles.playIcon"></span>
					{{'PlayAll' | translate }}
				</button>

				<a *ngIf="category && category.canEdit" class="margin-right-10" uiSref="portal.edit-category" [uiParams]="{parentCategoryId: category.id}" vbBtnPrimary>
					{{ 'Admin_AddSubcategory' | translate }}
				</a>

				<ng-container *ngIf="baseStateName === 'portal.media.browse'">
					<a *vbAuthorizationKey="'media.addCategory'" class="margin-right-10" uiSref="portal.edit-category" vbBtnPrimary>
						{{ 'Admin_AddCategory' | translate }}
					</a>
				</ng-container>

				<button type="button" *ngIf="isPortal && isAuthenticated && (
						baseStateName === 'portal.media.search' ||
						baseStateName === appStates.myPlaylist)" (click)="openNewPlaylistDialog()" vbBtnPrimary class="margin-right-10">
					{{ (baseStateName === 'portal.media.search' ?
						'Media_Playlists_SaveAs' :
						'Media_Videos_Playlists_NewPlaylist') | translate }}
				</button>

				<ng-container *ngIf="!MediaStateService.mediaState.isViewModeDisabled">
					<vb-sort-filter *ngIf="MediaStateService.mediaState.viewMode === 'tiles' && !MediaStateService.mediaState.isSortingDisabled" class="margin-right-10" [isSearch]="searchResultsState.searchQuery != null">
					</vb-sort-filter>
					<button type="button" (click)="MediaStateService.mediaState.viewMode === 'tiles' ? setViewMode('table') : setViewMode('tiles')" [attr.aria-label]="(MediaStateService.mediaState.viewMode === 'tiles' ? 'ListView' : 'GridView') | translate" [tooltip]="(MediaStateService.mediaState.viewMode === 'tiles' ? 'ListView' : 'GridView') | translate" placement="bottom right" vbBtnPrimary>
						<span class="glyphicons" [ngClass]="(MediaStateService.mediaState.viewMode === 'tiles' ? 'show_lines': 'show_big_thumbnails')"></span>
					</button>
				</ng-container>

			</div>

		</div>
	</div>
</div>
