import { Component } from '@angular/core';

import { MediaMenuService } from './MediaMenu.Service';
import { MY_PLAYLIST_STATE } from 'rev-shared/bootstrap/AppStateNames';

@Component({
	selector: 'my-videos-menu-items',
	templateUrl: './MyVideosMenuItems.Component.html'
})
export class MyVideosMenuItemsComponent {

	constructor(
		public MediaMenuService: MediaMenuService
	) { }

	public readonly MY_PLAYLIST_STATE = MY_PLAYLIST_STATE;

}
