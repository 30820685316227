import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

import styles from './CategoryRowRenderer.Component.module.less';
import { TABLE_DATA_MODE } from './Contract';

@Component({
	selector: 'category-row-renderer',
	templateUrl: './CategoryRowRenderer.Component.html'
})
export class CategoryRowRendererComponent implements ICellRendererAngularComp {
	public category: any;
	public readonly styles = styles;
	public dataMode: { [key in TABLE_DATA_MODE]?: boolean };

	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICellRendererParams): void {
		this.category = params.data || {};
		// this.dataMode = params.context?.dataMode;
	}
}
