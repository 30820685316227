import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FileUploadAngularModule } from 'rev-shared/ui/fileUpload/FileUpload.AngularModule';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { InsightModule } from 'rev-shared/ui/insight/ngx/Insight.Module';
import { InsightAngularModule } from 'rev-shared/ui/insight/InsightAngular.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';

import { AdminCategoryService } from './AdminCategory.Service';
import { EditCategoryComponent } from './EditCategory.Component';
import { ImageSelectorAngularModule } from 'rev-shared/ui/image-selector/ImageSelector.AngularModule';

@NgModule({
	declarations: [
		EditCategoryComponent
	],
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		CommonModule,
		FileUploadAngularModule,
		FormsModule,
		FormGroupAngularModule,
		ImageSelectorAngularModule,
		InsightModule,
		InsightAngularModule,
		PushModule,
		NgxTrimDirectiveModule,
		SpinnerAngularModule,
		TooltipModule,
		TranslateModule,
		ValidationModule,
		ValidationAngularModule,
		UIRouterModule.forChild()
	],
	providers: [
		AdminCategoryService
	]
})
export class SharedCategoryModule{}
