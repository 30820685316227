import { StateService, Transition, UIRouterGlobals, UrlService } from '@uirouter/angular';

import { CategoryService } from 'rev-shared/media/Category.Service';
import { ExpiryRuleSvc } from 'rev-shared/media/ExpiryRule.Service';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { MetadataService } from 'rev-shared/metadata/Metadata.Service';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';
import { TranscriptionLanguagesService } from 'rev-shared/media/transcription/TranscriptionLanguages.Service';
import { VideoService } from 'rev-shared/media/Video.Service';

import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';
import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';

import { BulkEditFormStateService } from './BulkEditFormState.Service';
import { VideoSelectionModelService } from './VideoSelectionModel.Service';
import { BulkEditVideosComponent } from './BulkEditVideos.Component';
import { BulkEditVideoSearchResultsComponent } from '../SearchResults.Component';
import { BulkGenerateMetadataComponent } from './BulkGenerateMetadata.Component';
import { RevIQService } from 'rev-shared/revIQ/RevIQ.Service';
import { BulkGenerateFormStateService } from './BulkGenerateFormState.Service';

export const states: IVbNg2StateDeclaration[] = [
	{
		name: 'portal.media.edit',
		url: '/edit?{q:any}',
		views: {
			'content@portal.media': {
				component: BulkEditVideoSearchResultsComponent
			}
		},

		params: {
			categoryId: undefined,
			keepFilterOverrides: true
		},

		scrollToTop: true,
		redirectTo(transition: Transition) {
			//doesn't work as an entry point (example: page refresh) since it will lack input/context
			if (!transition.from().name.length) {
				return {
					state: 'portal.media'
				};
			}
		},
		resolve: [
			{
				token: 'bulkEdit',
				resolveFn: () => true
			},
			{
				token: 'categoryId',
				deps: [Transition],
				resolveFn: (t: Transition) => t.params().categoryId
			},
			{
				token: 'forceMediaView',
				resolveFn: () => MediaViewMode.TABLE
			},
			{
				token: 'bulkEditAccessCheck',
				deps: ['userHasEditableVideos'],
				resolveFn: (userHasEditableVideos: boolean) => {
					if (!userHasEditableVideos) {
						return Promise.reject({ status: 401 });
					}

					return true;
				}
			},
			{
				token: 'searchParams',
				deps: [Transition, 'teamId'],
				resolveFn: ($transition$: Transition, teamId: string) => {
					return {
						query: $transition$.params().q,
						useEditAcl: true,
						teamId
					};
				}
			},
			{
				token: 'loadMetadataFields',
				deps: [MetadataService, 'accountId'],
				resolveFn: (MetadataService: MetadataService, accountId: string) => {
					return MetadataService.loadAccountVideoFields(accountId);
				}
			},
			{
				token: 'categories',
				deps: [CategoryService],
				resolveFn: (CategoryService: CategoryService) => {
					return CategoryService.getAssignableCategories();
				}
			},
			{
				token: 'expirationRules',
				deps: [ExpiryRuleSvc, 'accountId'],
				resolveFn: (ExpiryRuleSvc: ExpiryRuleSvc, accountId: string) => {
					return ExpiryRuleSvc.getExpiryRules(accountId);
				}
			},
			{
				token: 'mediaFeatures',
				deps: [MediaFeaturesService],
				resolveFn: (MediaFeatures: MediaFeaturesService) => {
					return MediaFeatures.getFeatures();
				}
			},
			{
				token: 'teams',
				deps: [VideoService],
				resolveFn: (VideoService: VideoService) => {
					return VideoService.getAssignableTeams()
						.then(results => results.map(team => ({ teamId: team.teamId, name: team.name })));
				}
			},
			{
				token: 'isRevIQAuthorizedWithValidAiCredits',
				deps: [RevIQService],
				resolveFn: (RevIQService: RevIQService) => {
					return RevIQService.isRevIQAuthorizedWithValidAiCredits();
				}
			},
			{
				token: 'aiSettings',
				deps: ['mediaFeatures', 'isRevIQAuthorizedWithValidAiCredits'],
				resolveFn: (
					mediaFeatures: IMediaFeatures,
					isRevIQAuthorizedWithValidAiCredits: boolean
				) => {
					return isRevIQAuthorizedWithValidAiCredits ? mediaFeatures?.aiSettings : null;
				}
			},
			{
				token: 'isBulkGenerateMetadataEnabled',
				deps: ['aiSettings', 'isRevIQAuthorizedWithValidAiCredits'],
				resolveFn: (
					aiSettings: any,
					isRevIQAuthorizedWithValidAiCredits: boolean
				) => {
					return isRevIQAuthorizedWithValidAiCredits && aiSettings?.enableVideoMetadataGeneration;
				}
			},
			{
				token: 'isBulkTranscribeEnabled',
				deps: ['mediaFeatures', 'isRevIQAuthorizedWithValidAiCredits'],
				resolveFn: (
					mediaFeatures: IMediaFeatures,
					isRevIQAuthorizedWithValidAiCredits: boolean
				) => {
					return isRevIQAuthorizedWithValidAiCredits && mediaFeatures?.transcriptionSettings?.isEnabledRevTranscription;
				}
			},
			{
				token: 'languages',
				deps: [TranscriptionLanguagesService],
				resolveFn: (TranscriptionLanguagesService: TranscriptionLanguagesService) => TranscriptionLanguagesService.loadAiServiceLanguages()
			},
		],
		onEnter($transition$: Transition) {
			const injector = $transition$.injector();
			const $state = injector.get<StateService>(StateService);
			const $globalState = injector.get<UIRouterGlobals>(UIRouterGlobals);
			const bulkEditFormState = injector.get<BulkEditFormStateService>(BulkEditFormStateService);
			const bulkGenerateFormState = injector.get<BulkGenerateFormStateService>(BulkGenerateFormStateService);
			const mediaFeatures = injector.get<IMediaFeatures>('mediaFeatures');
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			const videoSelectionModel = injector.get<VideoSelectionModelService>(VideoSelectionModelService);
			const $url = injector.get<UrlService>(UrlService);
			const isExpiration = $globalState.current.name === 'portal.media.expirations';
			const params = $transition$.params();

			mediaStateService.setIsViewModeDisabled(true);
			mediaStateService.setIsBulkEditDisabled(true);

			if (!stateChangeStatus.isStateReloading) {
				const backUrl = $url.url();
				mediaStateService.setBackLink(
					$globalState.current.name
						? '#' + (backUrl.includes('/media/edit') ? '/media/all' : backUrl) //if browser url is bulkedit then set the backurl to all video.
						: $state.href('^', null, { relative: this })
				);

				mediaStateService.setColumns(Object.assign({}, mediaStateService.getColumns(), {
					selection: 0
				}));

				bulkEditFormState.init();
				bulkGenerateFormState.init();
			}
			mediaStateService.setViewMode(MediaViewMode.TABLE);
			mediaStateService.setSortField(params.sortField || (isExpiration ? 'expiryDate' : mediaFeatures.defaultVideoSortOrder));
			if (isExpiration || params.desc) {
				mediaStateService.setIsSortDesc(!isExpiration && params.desc === 'true');
			} else {
				mediaStateService.setDefaultSortDirection();
			}
			videoSelectionModel.reset();
		},
		onExit(transition: Transition) {
			const injector = transition.injector();

			const bulkEditFormState = injector.get<BulkEditFormStateService>(BulkEditFormStateService);
			const bulkGenerateFormState = injector.get<BulkGenerateFormStateService>(BulkGenerateFormStateService);
			const searchFilterStateService = injector.get<SearchFilterStateService>(SearchFilterStateService);
			const mediaStateService = injector.get<MediaStateService>(MediaStateService);
			const stateChangeStatus = injector.get<StateChangeStatus>(StateChangeStatus);
			const videoSelectionModel = injector.get<VideoSelectionModelService>(VideoSelectionModelService);

			if(!stateChangeStatus.isStateReloading){
				searchFilterStateService.clearAllOverrides();
				mediaStateService.setIsViewModeDisabled(false);
				mediaStateService.setIsBulkEditDisabled(false);
				mediaStateService.setColumns(Object.assign({}, mediaStateService.getColumns(), {
					selection: -1
				}));

				if (stateChangeStatus.transition.toState.name !== 'portal.media.expirations') {
					mediaStateService.resetColumns();
				}

				mediaStateService.setBackLink();
				videoSelectionModel.reset();
				bulkEditFormState.clear();
				bulkGenerateFormState.clear();
			}
		}

	},
	{
		name: 'portal.media.edit.bulk-edit-sidebar',
		views: {
			'right-sidebar-content@portal.media.edit': {
				component: BulkEditVideosComponent
			}
		}
	},
	{
		name: 'portal.media.edit.bulk-edit-transcription-metadata-sidebar',
		views: {
			'right-sidebar-content@portal.media.edit': {
				component: BulkGenerateMetadataComponent
			}
		}
	}
];
