import { Injectable } from '@angular/core';

import { FileUtil } from 'rev-shared/util/FileUtil';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { ISupplementalContent } from 'rev-shared/media/videoSettings/VideoSettings.Contract';
import { PushService } from 'rev-shared/push/PushService';


@Injectable({
	providedIn: 'root'
})
export class VideoSupplementalFilesService {
	constructor(
		private PushService: PushService
	) {}

	public static getSupplementalContent(videoId: string, file: FileWrapper): ISupplementalContent {
		const isValid = VideoSupplementalFilesService.isValidSupplementalContentFile(file.extension);
		return {
			videoId,
			file: isValid ? file : undefined,
			filename: file.name,
			title: file.prettyName,
			extension: file.extension,
			isImageFile: file.isImageFile,
			error: !isValid ? { fileExtension: true } : undefined
		};
	}

	public static isValidSupplementalContentFile(extension: string): boolean {
		return FileUtil.isPresentationFile(extension) ||
			FileUtil.isDocumentFile(extension) ||
			FileUtil.isSpreadsheetFile(extension) ||
			FileUtil.isArchiveFile(extension) ||
			FileUtil.isImageFile(extension);
	}

	public uploadSupplementalContent(content: ISupplementalContent): Promise<void> {
		return this.PushService.dispatchCommand('media:AddMediaContent', {
			videoId: content.videoId,
			filename: content.filename
		}, 'MediaContentAdded')
			.then(result => {
				content.id = result.message.mediaContentId;
				content.file.setOptions({
					url: result.message.mediaContentUploadUri
				});
				return content.file.submit();
			});
	}

}
