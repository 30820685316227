import { Component, ElementRef, NgZone } from '@angular/core';

import { StateService } from '@uirouter/angular';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

import { IThumbnailSheetConfig } from 'rev-shared/ui/thumbnailSheetPreview/VbUiThumbnailSheetPreview.Component';
import { shapeThumbnailSheetConfig } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetUtility';

import styles from './VbMediaGridThumbnailCellRenderer.Component.module.less';

export interface IThumbnailRendererCfg {
	uiSref: string;
	uiSrefParams?: any;
	ariaLabel?: string;
	hideThumbnailStatus?: boolean
}

export interface IThumbnailCellRendererParams {
	getCellCfg(rowData: any): IThumbnailRendererCfg;
}

interface IMergedThumbnailCellRendererParams extends ICellRendererParams, IThumbnailCellRendererParams {}

@Component({
	selector: 'vb-media-grid-thumbnail-cell-renderer',
	templateUrl: './VbMediaGridThumbnailCellRenderer.Component.html',
	host: {
		'[class]': 'styles.root'
	},
})
export class VbMediaGridThumbnailCellRendererComponent implements ICellRendererAngularComp {
	public data: any;
	public context: any = {};
	public thumbnailSheets: IThumbnailSheetConfig;
	public readonly styles = styles;
	public thumbnailWidth: number;
	public thumbnailHeight: number;
	public config: IThumbnailRendererCfg;
	public thumbnailUri: string;
	public link: string;

	private resizeObserver: ResizeObserver;

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private zone: NgZone,
		private $state: StateService
	){}

	public ngAfterViewInit(): void {
		this.resizeObserver = new ResizeObserver(entries => {
			this.zone.run(() => {
				this.thumbnailWidth = this.elementRef.nativeElement.clientWidth;
				this.thumbnailHeight = this.thumbnailWidth * 0.5625; //16:9 ratio
			});
		});
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	public agInit(params: IMergedThumbnailCellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: IMergedThumbnailCellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: IMergedThumbnailCellRendererParams): void {
		const data = params.data || {};
		this.data = data;
		this.config = params.getCellCfg(data);
		this.context = params.context;
		this.thumbnailSheets = shapeThumbnailSheetConfig(this.data.thumbnailSheets);
		this.thumbnailUri = data.thumbnailUri || data.thumbnailUris?.[0]?.uri;
		this.link = this.$state.href(this.config.uiSref, this.config.uiSrefParams);
	}
}
