import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VbFlickityCarouselComponent } from './VbFlickityCarousel.AngularComponent';

const components = [VbFlickityCarouselComponent];
@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule
	]
})
export class FlickityCarouselModule {}
