import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { UnlistedVideoIndicatorComponent } from './UnlistedVideoIndicator.Component';
import { VbrickSharedMediaModule } from '../Media.Module';

const entryComponents = [
	UnlistedVideoIndicatorComponent
];

@NgModule({
	declarations: entryComponents,
	exports: entryComponents,
	imports: [
		CommonModule,
		TranslateModule,
		VbrickSharedMediaModule
	]
})
export class UnlistedVideoIndicatorAngularModule {}
