<ng-container *ngIf="multiSubscriptions; else singleSub;">
	<div class="dropdown" dropdown [insideClick]="true" #subscriptionDropdown="bs-dropdown">
		<button type="button" vbBtnPrimary dropdownToggle class="dropdown-toggle">
			<span class="glyphicons bell"></span>
			{{ (isSubscribed ? 'Unsubscribe' : 'Subscribe') | translate }}
		</button>
		<div *dropdownMenu class="dropdown-menu dropdown-menu-right" [ngClass]="styles.dropdownMenu">
			<ui style="display: flex; flex-direction: column">
				<li *ngFor="let subscription of subscriptionTypes; let index=index" class="padding-full-10" layout="row" flex-align="center" layout-wrap="false">
					<vb-toggle-button [name]="'subscription' + index" [noMinWidth]="true" [toggleId]="subscription.method" [ariaLabel]="subscription.subscribeLabel" [(ngModel)]="subscription.isSubscribed" (ngModelChange)="onSubscriptionChange(subscription)">
					</vb-toggle-button>
					<span> {{ subscription.subscribeLabel }} </span>
				</li>
			</ui>
		</div>
	</div>
</ng-container>
<ng-template #singleSub>
	<button type="button" vbBtnPrimary (click)="subscriptionTypes[0].isSubscribed=!subscriptionTypes[0].isSubscribed; onSubscriptionChange(subscriptionTypes[0])">
		<span class="glyphicons bell"></span>
		{{ (subscriptionTypes[0].isSubscribed ? subscriptionTypes[0].unsubscribeLabel : subscriptionTypes[0].subscribeLabel) | translate }}
	</button>
</ng-template>

