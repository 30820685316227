import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { once } from 'underscore';

import { PLAYLIST_ROOT_STATE } from 'rev-shared/bootstrap/AppStateNames';

const loadChildren = once(() =>
	import(/* webpackChunkName: 'mediaPlaylistModule' */ './Playlists.AngularModule')
		.then(mod => mod.PlaylistsAngularModule)
);

@NgModule({
	imports: [
		UIRouterModule.forChild({
			states: [{
				name: `${PLAYLIST_ROOT_STATE}.**`,
				url: '/playlists',
				loadChildren
			}]

		})
	]
})

export class PlaylistLoaderModule {
}
