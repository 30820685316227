export enum VideoPlaybackSidebarButton {
	AI = 'ai',
	CHAPTERS = 'chapters',
	COMMENTS = 'comments',
	INFO = 'info',
	PLAYLIST = 'playlist',
	PULSE = 'pulse',
	REPORTS = 'reports',
	REVIEW = 'review',
	SHARE = 'share'
}
