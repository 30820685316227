import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagifyModule } from 'ngx-tagify';

import { VideoSearchSidebarHeaderComponent } from 'rev-portal/media/search/VideoSearchSidebarHeader.Component';
import { VbVideoExpirationOptionsModule } from 'rev-portal/media/videos/videoExpirationOptions/VbVideoExpirationOptions.Module';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { InsightAngularModule } from 'rev-shared/ui/insight/InsightAngular.Module';
import { InsightModule } from 'rev-shared/ui/insight/ngx/Insight.Module';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ToolbarNgxModule } from 'rev-shared/ui/toolbar/ToolbarNgx.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbTagsInputModule } from 'rev-shared/ui/ngTagsInput/Ngx/VbTagsInput.Module';
import { VbUiDatePickerModule } from 'rev-shared/ui/datePicker/VbUiDatePicker.Module';
import { VirtualScrollTypeaheadAngularModule } from 'rev-shared/ui/virtualScrollTypeahead/VirtualScrollTypeahead.AngularModule';

import { BulkEditSubmissionDialogComponent } from './BulkEditSubmissionDialog.Component';
import { BulkEditVideosComponent } from './BulkEditVideos.Component';
import { BulkGenerateMetadataComponent } from './BulkGenerateMetadata.Component';
import { LegalHoldSidebarComponent } from './LegalHoldSidebar.Component';
import { VbDeleteVideosButtonComponent } from './VbDeleteVideosButton.Component';
import { states } from './StateDeclarations';

const exportComponents = [
	BulkEditSubmissionDialogComponent,
	BulkEditVideosComponent,
	BulkGenerateMetadataComponent,
	LegalHoldSidebarComponent,
	VbDeleteVideosButtonComponent
];

@NgModule({
	declarations: exportComponents,
	exports: exportComponents,
	imports: [
		BsDropdownModule,
		ButtonsAngularModule,
		CommonModule,
		DialogAngularModule,
		FormGroupAngularModule,
		FormsModule,
		InsightAngularModule,
		InsightModule,
		SelectAngularModule,
		TagifyModule,
		TextInputAngularModule,
		ToolbarNgxModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild({ states }),
		ValidationAngularModule,
		ValidationModule,
		VbTagsInputModule,
		VbUiDatePickerModule,
		VbVideoExpirationOptionsModule,
		VideoSearchSidebarHeaderComponent,
		VirtualScrollTypeaheadAngularModule
	]
})
export class BulkEditModule {

	constructor(dialog: DialogRegistrationService) {
		dialog.register('bulkEditSubmission', {
			component: BulkEditSubmissionDialogComponent
		});
	}
}
