import {
	Component,
	OnInit
} from '@angular/core';

import { ScheduledEventService } from 'rev-shared/scheduledEvents/ScheduledEvent.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import styles from 'rev-portal/dashboard/carousels/dashboard-carousel.module.less';

@Component({
	selector: 'events-carousel',
	templateUrl: './EventsCarousel.Component.html'
})

export class EventsCarouselComponent implements OnInit {
	public readonly styles = styles;

	private accountId: string;
	public events: any[];

	constructor(
		private ScheduledEventService: ScheduledEventService,
		public UserContext: UserContextService
	) {}

	public ngOnInit(): void {
		setTimeout(() => {
			this.accountId = this.UserContext.getAccount().id;
			this.loadUpcomingEvents();
		});
	}

	private loadUpcomingEvents(): void {
		this.ScheduledEventService.getUpcomingEvents(this.accountId)
			.then(events => this.events = events);
	}
}
