<form #form="ngForm" (submit)="onSubmit()">
	<vb-ui-form-group>
		<label #uiLabel for="templateInput">
			{{ 'Templates_SelectTemplate' | translate }}
		</label>
		<ng-container uiControl>
			<input id="templateInput" type="text" autocomplete="off" name="selectedTemplate" vbUiTextInput [disabled]="templates.length === 0" [(ngModel)]="template" (ngModelChange)="clearSelectedTemplate()" [typeahead]="templates" typeaheadOptionField="name" [typeaheadWaitMs]="typeaheadDelayMs" [typeaheadMinLength]="0" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="15" [typeaheadOptionsLimit]="999" (typeaheadOnSelect)="onSelect($event.item)"/>

			<span *ngIf="noTemplates">{{ 'Templates_NoTemplatesAvailable' | translate }}</span>
		</ng-container>
	</vb-ui-form-group>

	<vb-ui-toolbar [ngClass]="styles.toolbar">
		<button type="button" class="margin-right-5" vbBtnSecondary (click)="close()">
			{{ 'Cancel' | translate }}
		</button>
		<button type="submit" [disabled]="!selectedTemplate" vbBtnPrimary>
			{{ 'Apply' | translate }}
		</button>
	</vb-ui-toolbar>
</form>
