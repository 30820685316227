import { Injectable } from '@angular/core';

export enum BulkGenerateOperator {
	Generate = 'Generate',
	Append = 'Append',
	Replace = 'Replace',
	DoNotGenerate = 'DoNotGenerate'
}

export interface IBulkGenerateOperation {
	languageId?: string;
	fieldType?: string;
	action: BulkGenerateOperator;
}

export interface IBulkGenerateForm {
	generateTranscript: IBulkGenerateFormControl;
	generateTitle: IBulkGenerateFormControl;
	generateDescription: IBulkGenerateFormControl;
	generateTags: IBulkGenerateFormControl;
	generateChapters: IBulkGenerateFormControl;
}

export interface IBulkGenerateFormControl {
	languageId?: string;
	languageName?: string;
	value: BulkGenerateOperator;
}

@Injectable({
	providedIn: 'root'
})
export class BulkGenerateFormStateService {
	public fields: IBulkGenerateForm;

	public init(): void {
		this.fields = this.getDefaultTranscriptionMetadataFields() as IBulkGenerateForm;
	}

	public clear(): void {
		this.fields = this.getDefaultTranscriptionMetadataFields() as IBulkGenerateForm;
	}

	public getTranscriptOperation(): IBulkGenerateOperation {
		const field = this.fields.generateTranscript;
		return field?.value !== BulkGenerateOperator.DoNotGenerate && field.languageId ? {
			languageId: field.languageId,
			action: field.value
		} : null;
	}

	public getMetadataGenerationOperations(): Array<{ fieldType?: string, action: BulkGenerateOperator; }> {
		const operations = [
			this.getTitleOperation(),
			this.getDescriptionOperation(),
			this.getTagsOperation(),
			this.getChaptersOperation()
		].filter(op => op !== null);
		return operations;
	}

	public hasAnyOperations(): boolean {
		const { generateTranscript, generateTitle, generateDescription, generateTags, generateChapters } = this.fields;
		const doNotGenerate = BulkGenerateOperator.DoNotGenerate;

		return [generateTranscript, generateTitle, generateDescription, generateTags, generateChapters]
			.some(field => field.value !== doNotGenerate);
	}

	private getTitleOperation(): IBulkGenerateOperation {
		return this.getOperation(this.fields.generateTitle, 'title');
	}

	private getDescriptionOperation(): IBulkGenerateOperation {
		return this.getOperation(this.fields.generateDescription, 'description');
	}

	private getTagsOperation(): IBulkGenerateOperation {
		return this.getOperation(this.fields.generateTags, 'tags');
	}

	private getChaptersOperation(): IBulkGenerateOperation {
		return this.getOperation(this.fields.generateChapters, 'chapters');
	}

	private getOperation(field: any, fieldType: string): IBulkGenerateOperation {
		return field?.value !== BulkGenerateOperator.DoNotGenerate ? {
			fieldType: fieldType,
			action: field.value
		} : null;
	}

	private getDefaultTranscriptionMetadataFields(): Partial<IBulkGenerateForm> {
		return {
			generateTranscript: { value: BulkGenerateOperator.Generate },
			generateTitle: { value: BulkGenerateOperator.DoNotGenerate },
			generateDescription: { value: BulkGenerateOperator.Generate },
			generateTags: { value: BulkGenerateOperator.Generate },
			generateChapters: { value: BulkGenerateOperator.Generate }
		};
	}
}
