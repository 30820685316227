import { Component } from '@angular/core';
import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { Column, ColumnApi, GridApi, ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { StateService } from '@uirouter/angular';

import { SearchHeaderService } from 'rev-portal/navigation/searchHeader/SearchHeader.Service';
import { VideoPlaybackSidebarButton } from 'rev-portal/media/videos/videoPlayback/VideoPlaybackSidebarModel';

import styles from './VideoTitleCellRenderer.Component.module.less';

@Component({
	selector: 'video-title-cell-renderer',
	templateUrl: './VideoTitleCellRenderer.Component.html',
	host: {
		'[class]': 'styles.root'
	}
})
export class VideoTitleCellRendererComponent implements ICellRendererAngularComp {
	public video: any;
	public context: any = {};
	public readonly styles = styles;
	public rowNode: IRowNode;
	public columnApi: ColumnApi;
	public column: Column;
	public api: GridApi;

	constructor(
		private $state: StateService,
		private SearchHeaderService: SearchHeaderService
	){}

	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICellRendererParams): void {
		//sometimes grid data is null while grid is loading.
		this.video = params.data || {};
		this.context = params.context;
		this.rowNode = params.node;
		this.columnApi = params.columnApi;
		this.column = params.column;
		this.api = params.api;
	}

	public onSearchMoreTranscripts(): void {
		this.$state.go('portal.video', { videoId: this.video.id, config: this.context.videoPlaybackConfig, tab: VideoPlaybackSidebarButton.PULSE, tabFilterQuery: this.SearchHeaderService.searchQuery });
	}
}
