import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AppBootstrapService } from 'rev-shared/bootstrap/AppBootstrap.Service';
import { FileUtil } from 'rev-shared/util/FileUtil';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IAccountLicense } from 'rev-shared/security/IAccountLicense';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';

import { UserProfileService } from './UserProfile.Service';
import styles from './UserProfile.module.less';
import { IImageData } from 'rev-shared/ui/image-selector/ImageSelector.Contract';
import { UserProfileBasicSettingsComponent } from './UserProfileBasicSettings.Component';

const MaxProfileSize = 5 * FileUtil.Mb;

@Component({
	selector: 'user-profile',
	templateUrl: './UserProfile.Component.html',
	host: {
		'class': 'theme-primary box-block'
	}
})
export class UserProfileComponent implements OnInit, OnDestroy {
	@Input() public accountLicense: IAccountLicense;
	@Input() public backTransition: { name: string; params: any };
	@Input() public user: any;
	@Input() public features: IMediaFeatures;

	@ViewChild(UserProfileBasicSettingsComponent) private basicSettings: UserProfileBasicSettingsComponent;

	public readonly styles = styles;

	private originalLanguage: string;
	private unsubscribe: IUnsubscribe;
	public imageData: IImageData;
	public saving: boolean;
	public userBasicSettings: any;

	constructor(
		private $state: StateService,
		private AppBootstrapService: AppBootstrapService,
		private PushBus: PushBus,
		private UserProfileService: UserProfileService
	) {
	}

	public ngOnInit(): void {
		this.originalLanguage = this.user.language = this.user.language || '';
		this.userBasicSettings =
		{
			disableFacialRecognition: this.user.disableFacialRecognition,
			email: this.user.email,
			firstName: this.user.firstName,
			isLdapProfileImage: this.user.isLdapProfileImage,
			language: this.user.language,
			lastName: this.user.lastName,
			phone: this.user.phone,
			profileImageId: this.user.profileImageId,
			profileImageUri: this.user.profileImageUri,
			sourceType: this.user.sourceType,
			title: this.user.title,
			username: this.user.username,
			isProfileImageProcessing: this.user.isProfileImageProcessing
		};
		this.unsubscribe = this.subscribePush();
	}

	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	public close(): void {
		const name = this.backTransition.name || this.AppBootstrapService.getAppLandingStateName();

		if(this.user.language !== this.originalLanguage) {
			//if language changes, force page refresh
			window.location.href = this.$state.href(name, {});
			return window.location.reload();
		}

		this.$state.transitionTo(name, this.backTransition.params);
	}

	public handleImage(imageData): void {
		this.imageData = imageData;
	}

	public submit(): void {
		this.user = { ...this.user, ...this.userBasicSettings };
		this.saving = true;
		if(!this.imageData) {
			this.user.profileImageId = null;
		}

		this.UserProfileService.saveProfile({
			...this.user,
			language: this.user.language || null
		},
		this.imageData?.fileWrapper)
			.then(() => this.close())
			.catch(e => {
				if(e.hasIssue && e.hasIssue('EmailInUse')) {
					this.basicSettings.email.control.setErrors({ emailInUse: true });
				}
				console.error('unable to save profile', e);
				this.saving = false;
			});
	}

	private subscribePush(): IUnsubscribe {
		return this.PushBus.subscribe(this.user.id, 'User', {
			UserProfileImageUploadFinalized: userInfo => {
				this.user = { ...this.user, ...userInfo, isProfileImageProcessing: false };
				this.userBasicSettings = { ...this.userBasicSettings, ...userInfo, isProfileImageProcessing: false };
			}
		});
	}
}
