<a [ngClass]="styles.thumbnailAnchor" uiSref="portal.video" [uiParams]="{ videoId:video.id, config: context.videoPlaybackConfig }" [attr.aria-label]="'Media_Video_GoToVideo' | translate : { '0' : video.title }">
	<div [ngClass]="styles.thumbnailWrapper" [ngStyle]="{ height: thumbnailHeight + 'px'}">
		<div [ngClass]="styles.icon" class="glyphicons lock" [hidden]="!video.legalHold">
		</div>
		<div [ngClass]="styles.icon" class="glyphicons volume_up" [hidden]="!video.hasAudioOnly"></div>
		<div [ngClass]="styles.vbIcon" class="video-status-public-password" *ngIf="context.isGuest && video.hasPassword" [title]="'PasswordProtected' | translate">
			<span class="vb-icon vb-icon-password"></span>
		</div>
		<vb-ui-thumbnail-sheet-preview class="thumbnail-preview" [ngClass]="styles.thumbnailSheetWrapper" [thumbnailUri]="video.thumbnailUri || (video.hasAudioOnly?'/shared/img/audio-thumbnail.png':'/shared/img/default-thumbnail.png')" [thumbnailSheetCfg]="thumbnailSheets" [width]="thumbnailWidth" [height]="thumbnailHeight" [msPerFrame]="233" [cssClass]="styles.thumbnailPreview">
		</vb-ui-thumbnail-sheet-preview>
		<video-thumbnail-status [approvalStatus]="video.approval?.status" [videoIsActive]="video.isActive" [videoStatus]="video.status" [videoThumbnailUri]="video.thumbnailUri" [cssClass]="styles.thumbnailStatus">
		</video-thumbnail-status>
	</div>
</a>

