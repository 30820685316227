import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { InlineEditAngularModule } from 'rev-shared/ui/inlineEdit/InlineEdit.AngularModule';
import { MediaUploadBtnModule } from 'rev-shared/ui/mediaUploadBtn/MediaUploadBtn.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SharedTeamAngularModule } from 'rev-shared/team/SharedTeam.AngularModule';
import { SignInBtnModule } from 'rev-shared/ui/signInBtn/SignInBtn.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbUserSubscriptionModule } from 'rev-shared/userSubscription/VbUserSubscription.Module';

import { SparkModule } from 'rev-portal/media/spark/Spark.Module';

import { NewPlaylistDialogComponent } from './NewPlaylistDialog.Component';
import { VbMediaTeamsHeaderComponent } from './VbMediaTeamsHeader.Component';
import { VbMediaToolbarComponent } from './VbMediaToolbar.Component';
import { VbSortFilterComponent } from './VbSortFilter.Component';

import styles from './NewPlaylistDialog.module.less';

@NgModule({
	imports: [
		BsDropdownModule,
		ButtonsAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		FormsModule,
		FormGroupAngularModule,
		InlineEditAngularModule,
		MediaUploadBtnModule,
		SecurityAngularModule,
		SharedTeamAngularModule,
		SignInBtnModule,
		SparkModule,
		TooltipModule,
		TranslateModule,
		UIRouterModule.forChild(),
		ValidationAngularModule,
		ValidationModule,
		VbUserSubscriptionModule
	],
	declarations: [
		NewPlaylistDialogComponent,
		VbSortFilterComponent,
		VbMediaToolbarComponent,
		VbMediaTeamsHeaderComponent
	],

	bootstrap: [
		VbMediaToolbarComponent
	],
	exports: [
		VbMediaToolbarComponent
	]
})
export class MediaSearchToolbarModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('NewPlaylistDialog', {
			component: NewPlaylistDialogComponent,
			class: styles.newPlaylistDialog
		});
	}
}
