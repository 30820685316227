import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TagifyModule } from 'ngx-tagify';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UIRouterModule } from '@uirouter/angular';

import { GoogleAnalyticsModule } from 'rev-shared/ui/googleAnalytics/GoogleAnalytics.Module';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { MomentLocaleLoader } from 'rev-shared/date/MomentLocaleLoader.Provider';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { PushModule } from 'rev-shared/push/Push.Module';
import { RootDialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { SECURITY_STARTUP_INJECTION_TOKEN } from 'rev-shared/security/SecurityStartup.Provider';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SecurityComponentsModule } from 'rev-shared/security/components/SecurityComponents.Module';
import { SignalRStartupToken } from 'rev-shared/push/SignalRStartupToken';
import { SpinnerRootModule } from 'rev-shared/ui/spinner/SpinnerRoot.Module';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { initUserContextMessageHandlers } from 'rev-shared/security/UserContextMessageHandlers';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { MainHeaderAngularModule } from 'rev-portal/navigation/mainHeader/MainHeader.AngularModule';
import { PortalAppComponent } from 'rev-portal/PortalApp.Component';
import { PortalComponent } from 'rev-portal/Portal.Component';
import { SystemMessageModule } from 'rev-portal/systemMessage/SystemMessage.Module';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { VbPortalStylesComponent } from 'rev-portal/VbPortalStyles.Component';

import { EntryPointStartup, getEntryPointStartup } from './EntryPointStartup.Provider';

import 'rev-less/main.less';
import 'rev-less/admin/admin-main.less'; //Mostly shared form styles
import 'vbrick-icon-font/dist/vb-icon-font.css';

@NgModule({
	declarations: [
		VbPortalStylesComponent,
		PortalComponent,
		PortalAppComponent
	],
	exports: [
		VbPortalStylesComponent,
		PortalComponent,
		PortalAppComponent
	],
	imports: [
		AlertModule.forRoot(),
		BrandingAngularModule,
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		ButtonsModule.forRoot(),
		CollapseModule.forRoot(),
		CommonModule,
		GoogleAnalyticsModule,
		MainHeaderAngularModule,
		PushModule.forRoot(),
		RatingModule.forRoot(),
		RootDialogAngularModule,
		SecurityAngularModule,
		SecurityComponentsModule,
		SpinnerRootModule,
		SystemMessageModule,
		TabsModule.forRoot(),
		TagifyModule.forRoot(),
		TooltipModule.forRoot(),
		TypeaheadModule,
		UIRouterModule
	],
	providers: [
		{
			provide: EntryPointStartup,
			useFactory: getEntryPointStartup,
			deps: [
				MomentLocaleLoader,
				SECURITY_STARTUP_INJECTION_TOKEN,
				ThemeService,
				SignalRStartupToken
			]
		}
	]
})
export class EntryPointAngularModule {
	constructor(LoginRedirectService: LoginRedirectService, PushBus: PushBus, UserContext: UserContextService)
	{
		initUserContextMessageHandlers(LoginRedirectService, PushBus, UserContext);
	}
}
