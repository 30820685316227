import { Component, ElementRef, NgZone } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

import { IThumbnailSheetConfig } from 'rev-shared/ui/thumbnailSheetPreview/VbUiThumbnailSheetPreview.Component';
import { shapeThumbnailSheetConfig } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetUtility';

import styles from './VideoThumbnailCellRenderer.Component.module.less';

@Component({
	selector: 'video-thumbnail-cell-renderer',
	templateUrl: './VideoThumbnailCellRenderer.Component.html',
	host: {
		'[class]': 'styles.root'
	},
})
export class VideoThumbnailCellRendererComponent implements ICellRendererAngularComp {
	public video: any;
	public context: any = {};
	public thumbnailSheets: IThumbnailSheetConfig;
	public readonly styles = styles;
	public thumbnailWidth: number;
	public thumbnailHeight: number;

	private resizeObserver: ResizeObserver;

	constructor(
		private elementRef: ElementRef<HTMLElement>,
		private zone: NgZone
	){}

	public ngAfterViewInit(): void {
		this.resizeObserver = new ResizeObserver(entries => {
			this.zone.run(() => {
				this.thumbnailWidth = this.elementRef.nativeElement.clientWidth;
				this.thumbnailHeight = this.thumbnailWidth * 0.5625; //16:9 ratio
			});
		});
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: ICellRendererParams): void {
		const data = params.data || {};
		this.video = data;
		this.context = params.context;
		this.thumbnailSheets = shapeThumbnailSheetConfig(this.video.thumbnailSheets);
	}
}
