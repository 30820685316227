import {
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { Subscription } from 'rxjs';

import { identity } from 'rev-shared/util';

import { Filter, MultiValueFilter } from './SearchFilterTypes';
import { SearchFilterStateService, getFilterValues } from './SearchFilterState.Service';

import './VbSearchFilterList.less';

@Component({
	selector: 'vb-search-filter-list',
	templateUrl: './VbSearchFilterList.Component.html'
})
export class VbSearchFilterListComponent implements OnDestroy, OnInit {
	@Input() public noStates: boolean;

	public filters: Array<{
		clear: () => void;
		format: any;
		name: string;
	}>;

	public filterNames = {
		searchTerm: 'Media_Videos_Search',
		title:'Title',
		description:'Description',
		whenUploaded:'Media_Search_Filters_UploadDate',
		ownerUserId:'Media_Videos_Owner',
		uploaderUserId:'Media_Videos_Uploader',
		isActive:'Media_Videos_Status',
		is360:'Filter360Video',
		isLive:'VideoType',
		teamIds:'Title_Team',
		unlisted:'Unlisted',
		userTags:'InThisVideo',
		tags:'Tags',
		categoryIds:'Category'
	};

	private searchFilterStateSub: Subscription;
	public showClearAllButton: boolean;

	constructor(
		private SearchFilterState: SearchFilterStateService,
		private $state: StateService,
		private TranslateService: TranslateService
	) {
	}

	public ngOnInit(): void {
		this.searchFilterStateSub = this.SearchFilterState.change$.subscribe(() => this.initializeFilters());

		window.setTimeout(() => this.initializeFilters());
	}

	public ngOnDestroy(): void {
		this.searchFilterStateSub.unsubscribe();
	}

	public clearAll(): void {
		if(this.noStates) {
			this.SearchFilterState.clear();
			return;
		}
		this.SearchFilterState.go({}, this.$state);
	}

	private getFormatter(filter: Filter): (value: any) => string {
		if(filter.formatterFactory){
			return filter.formatterFactory(this.TranslateService);
		}

		return (filter.formatter || identity).bind(filter);
	}

	private initializeFilters(): void {
		this.filters = Object.keys(this.SearchFilterState.filters)
			.map(name => {
				const filter = this.SearchFilterState.filters[name];

				const hasEditableOverride = filter.hasValueOverride && !filter.blockUserUpdates;
				const hasValue = filter.hasUserEnteredValue || hasEditableOverride;

				if (!hasValue || !this.filterNames[name]) {
					return;
				}

				const formatter = this.getFormatter(filter);

				if (filter.isSingleValue) {
					return {
						name,
						format: () => formatter(filter.value),
						clear: () => {
							filter.clear();
							this.update();
						}
					};
				}

				//multivalue
				return filter.value.map((value, i) => ({
					name,
					format: () => formatter(value),
					clear: () => {
						(filter as MultiValueFilter).removeSingleValue(i);
						this.update();
					}
				}));
			})
			.reduce((a, b) => a.concat(b), [])
			.filter(Boolean);

		this.showClearAllButton = this.filters.length > 0;
	}

	public update(): void {
		if(this.noStates) {
			this.SearchFilterState.setFilters(this.SearchFilterState.filters);
			return;
		}

		const filters = getFilterValues(this.SearchFilterState.filters, true);

		this.SearchFilterState.go(filters, this.$state);
	}
}
