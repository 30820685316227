import { AlertModule } from 'ngx-bootstrap/alert';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { AccentHeaderAngularModule } from 'rev-shared/ui/accentHeader/AccentHeader.AngularModule';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { ImageSelectorAngularModule } from 'rev-shared/ui/image-selector/ImageSelector.AngularModule';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TabPanelModule } from 'rev-shared/ui/tabPanel/TabPanel.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';

import { UserProfileComponent } from './UserProfile.Component';
import { UserProfileBasicSettingsComponent } from './UserProfileBasicSettings.Component';
import { UserProfileSecuritySettingsComponent } from './UserProfileSecuritySettings.Component';
import { UserProfileService } from './UserProfile.Service';
import { states } from './UserProfile.StateConfig';

@NgModule({
	id: 'vbrick.userProfile',
	declarations: [
		UserProfileComponent,
		UserProfileBasicSettingsComponent,
		UserProfileSecuritySettingsComponent
	],
	imports: [
		AccentHeaderAngularModule,
		AlertModule,
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		DirectivesAngularModule,
		FormDirectivesAngularModule,
		FormGroupAngularModule,
		FormsModule,
		ImageSelectorAngularModule,
		NgxTrimDirectiveModule,
		PushModule,
		SecurityAngularModule,
		SelectAngularModule,
		SpinnerAngularModule,
		TabPanelModule,
		TextInputAngularModule,
		TranslateModule,
		UIRouterModule.forChild({
			states
		}),
		ValidationAngularModule,
		ValidationModule
	],
	providers: [
		UserProfileService
	]
})
export class UserProfileModule {
}
