import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { PushModule } from 'rev-shared/push/Push.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { NotificationComponent } from './Notification.Component';
import { NotificationService } from './Notification.Service';
import { states } from './StateDeclarations';
import { UserNotificationsComponent } from './UserNotifications.Component';
import { UserNotificationsService } from './UserNotifications.Service';

const components = [
	NotificationComponent,
	UserNotificationsComponent
];

@NgModule({
	imports: [
		CommonModule,
		DateAngularModule,
		PushModule,
		SecurityAngularModule,
		SpinnerAngularModule,
		TranslateModule,
		UIRouterModule.forChild({
			states
		}),
	],
	declarations: components,

	exports: [
		NotificationComponent
	],
	providers: [
		NotificationService,
		UserNotificationsService
	]
})
export class NotificationAngularModule {
}
