<vb-title [title]="'ManageSubscriptions' | translate"></vb-title>
<vb-page-header [headerText]="'ManageSubscriptions' | translate"></vb-page-header>

<vb-ui-tab-panel [ngClass]="styles.tabPanel" flex="fill" class="height-full" [verticalFill]="false">
	<vb-ui-tab #channelsTab *ngIf="MediaFeaturesService.accountFeatures.enableContentNotifications" [heading]="'Title_Teams' | translate">
		<vb-ui-data-grid *ngIf="channelsTab.isActive" [ngClass]="styles.dataGrid" [columnDefs]="channelColumnDefs" [rowData]="channels" [autoSizeAllColumns]="true" (gridReady)="onChannelGridReady($event.api)">
		</vb-ui-data-grid>
	</vb-ui-tab>
	<vb-ui-tab #categoriesTab [heading]="'Title_Categories' | translate" *ngIf="MediaFeaturesService.accountFeatures.enableCategories">
		<vb-ui-data-grid *ngIf="categoriesTab.isActive" [ngClass]="styles.dataGrid" [columnDefs]="categoriesColumnDefs" [rowData]="categories" [autoSizeAllColumns]="true" (gridReady)="onCategoryGridReady($event.api)">
		</vb-ui-data-grid>
	</vb-ui-tab>
</vb-ui-tab-panel>
