import { Component, Input, OnChanges } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { MediaStateService, SortField } from 'rev-portal/media/MediaState.Service';

import styles from './VbSortFilter.module.less';

export const SortFilterLinks = {
	score: {
		sort: SortField.Score,
		label: 'Media_CurrentSortValue_Relevance'
	},
	recommended: {
		sort: SortField.Recommended,
		desc: true,
		label: 'Media_Recommended'
	},
	whenUploaded: {
		sort: SortField.WhenUploaded,
		desc: true,
		label: 'Media_CurrentSortValue_UploadDate'
	},
	title: {
		sort: SortField.Title,
		label: 'Media_CurrentSortValue_Title'
	},
	viewCount: {
		sort: SortField.ViewCount,
		desc: true,
		label: 'Media_Videos_Views'
	},
	ownerName: {
		sort: SortField.OwnerName,
		label: 'Media_Videos_Owner'
	},
	duration : {
		sort: SortField.Duration,
		label: 'Duration'
	},
	lastViewed: {
		sort: SortField.LastViewed,
		label: 'Media_Videos_LastViewedDate'
	}
};

@Component({
	selector: 'vb-sort-filter',
	templateUrl: './VbSortFilter.Component.html'
})
export class VbSortFilterComponent implements OnChanges {
	@Input() public isSearch: boolean;

	public activeLink: any;
	public links: any[];
	public readonly styles = styles;

	constructor(
		public MediaStateService: MediaStateService,
		private $state: StateService
	) {}

	public ngOnChanges(): void {

		this.links = [
			this.isSearch && SortFilterLinks.score,
			this.MediaStateService.showSortRecommended && SortFilterLinks.recommended,
			SortFilterLinks.whenUploaded,
			SortFilterLinks.title,
			SortFilterLinks.viewCount
		].filter(Boolean);


		const sortField = this.MediaStateService.getSortField();
		this.activeLink = this.links.find(l => l.sort === sortField);
	}

	public setSortField(link: any): void {
		this.activeLink = link;
		this.MediaStateService.setSortField(link.sort);
		this.MediaStateService.setIsSortDesc(!!link.desc);
		this.onChange();
	}

	public onChange(): void {
		this.$state.go('.', this.MediaStateService.getQueryParams(), { reload: true });
	}
}
