import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, NgZone, OnDestroy, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RatingModule } from 'ngx-bootstrap/rating';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';
import { VideoTextStatusAngularModule } from 'rev-portal/media/videos/videoTextStatus/VideoTextStatus.AngularModule';
import { VideoThumbnailStatusAngularModule } from 'rev-portal/media/videos/videoThumbnailStatus/VideoThumbnailStatus.AngularModule';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { IThumbnailSheetConfig } from 'rev-shared/ui/thumbnailSheetPreview/VbUiThumbnailSheetPreview.Component';
import { ThumbnailSheetPreviewModule } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetPreview.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { shapeThumbnailSheetConfig } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetUtility';

import styles from './VideoTile.Component.module.less';

@Component({
	standalone: true,
	imports: [
		VideoTextStatusAngularModule,
		VideoThumbnailStatusAngularModule,
		CommonModule,
		TranslateModule,
		ThumbnailSheetPreviewModule,
		UIRouterModule,
		VbProfilePictureModule,
		Video360IndicatorModule,
		DateAngularModule,
		RatingModule,
		FormsModule
	],
	selector: 'video-tile',
	templateUrl: './VideoTile.Component.html'
})
export class VideoTileComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public config: IVideoPlaybackConfig;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public isGuest: boolean;
	@Input() public mediaFeatures: any;
	@Input() public playlist: any;
	@Input() public userId: string;
	@Input() public video: any;
	@Input() public hideVideoStatusText: boolean;
	@Input() public isResponsiveTile: boolean = true;
	@ViewChild('fixedRatioWrapper') public fixedRatioWrapper: ElementRef;

	public readonly styles = styles;
	public readonly approvalStatusOptions = ApprovalStatus;
	public readonly videoStatusOptions = VideoStatus;

	public hostClass = this.styles.root;
	public responsiveHostClass = this.styles.responsiveRoot;
	public thumbnailWidth: number;
	public thumbnailHeight: number;
	public thumbnailSheets: IThumbnailSheetConfig;
	private resizeObserver: ResizeObserver;

	constructor(
		private zone: NgZone
	) { }

	public ngOnInit(): void {
		this.thumbnailSheets = shapeThumbnailSheetConfig(this.video.thumbnailSheets);
	}

	public ngAfterViewInit(): void {
		this.resizeObserver = new ResizeObserver(entries => {
			this.zone.run(() => {
				this.thumbnailWidth = entries[0].contentRect.width;
				this.thumbnailHeight = entries[0].contentRect.height;
			});
		});
		this.resizeObserver.observe(this.fixedRatioWrapper.nativeElement);
	}

	public ngOnDestroy(): void {
		this.resizeObserver.unobserve(this.fixedRatioWrapper.nativeElement);
	}

	@HostBinding('class')
	public get class() {
		return this.isResponsiveTile ? `${this.styles.root} ${this.styles.responsiveRoot}` : this.styles.root;
	}

	public get ownerName(): string {
		return this.video.ownerName ? this.video.ownerName : [this.video.ownerFirstName, this.video.ownerLastName].filter(Boolean).join(' ');
	}

	public get uploadDate(): Date {
		return this.video.whenUploaded ? this.video.whenUploaded : this.video.createdBy.when;
	}

	public get videoStatus(): string {
		return this.video.status;
	}

	public get uploaderName(): string {
		return [this.video.uploaderFirstName, this.video.uploaderLastName].filter(Boolean).join(' ');
	}

	public get hasEditVideoAuth(): boolean {
		return this.video && (this.video.editAcl || []).includes(this.userId);
	}

	public get isUploading(): boolean {
		return this.videoStatus === VideoStatus.NOT_UPLOADED ||
			this.videoStatus === VideoStatus.UPLOADING;
	}

	public get isProcessing(): boolean {
		return this.videoStatus === VideoStatus.PROCESSING ||
			this.videoStatus === VideoStatus.UPLOADING_FINISHED;
	}

	public get isProcessingFailed(): boolean {
		return this.videoStatus === VideoStatus.UPLOAD_FAILED ||
			this.videoStatus === VideoStatus.PROCESSING_FAILED ||
			this.videoStatus === VideoStatus.READY_BUT_PROCESSING_FAILED;
	}

	public get isPasswordProtected(): boolean {
		return this.isGuest && this.video.hasPassword;
	}
}
