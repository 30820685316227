import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

import { IManageSubscriptionItem } from './IManageSubscriptionItem';

import styles from './ManageSubscriptions.module.less';

@Component({
	selector: 'category-cell-renderer',
	template: `
	<div [ngClass]="styles.categoryContainer">
		<span><strong>{{ manageSubscriptionItem.label }}</strong></span>
		<span>{{ manageSubscriptionItem.path }}</span>
	</div>
	`
})
export class CategoryCellRendererComponent implements ICellRendererAngularComp {
	public manageSubscriptionItem: IManageSubscriptionItem;
	public readonly styles = styles;

	public refresh(params: ICellRendererParams): boolean {
		this.updateCell(params);
		return true;
	}

	public agInit(params: ICellRendererParams): void {
		this.updateCell(params);
	}

	private updateCell(params: ICellRendererParams) {
		this.manageSubscriptionItem = params.data;
	}

}
