<ng-template #defaultHelpLink>
	<a class="mb-0" target="_blank" rel="noopener noreferrer" [ngClass]="{'theme-accent-btn': isNettrixTheme}" [href]="helpUri" role="menuitem">
		{{ 'Help' | translate }}
	</a>
</ng-template>

<ng-container *ngIf="hasCustomHelpLinks; else defaultHelpLink">
	<button type="button" class="margin-left-15 margin-bottom-0" (click)="syncModelValue(); $event.stopPropagation();" [ngClass]="{active: showHelpSubMenu,
			'theme-accent-btn': isNettrixTheme
		}" role="menuitem">
		{{ 'Help' | translate }}
		<i class="float-right glyphicons animated menu-item-icon" [ngClass]="{'chevron-right': !showHelpSubMenu,
						'chevron-down flipInX': showHelpSubMenu,
						'theme-accent-btn': isNettrixTheme}">
		</i>
	</button>
	<ul class="help-menu-list" role="menu" *ngIf="showHelpSubMenu">
		<li role="none">
			<a class="margin-top-0 help-menu-item" target="_blank" rel="noopener noreferrer" [href]="helpUri" role="menuitem">
				<span class="text-label">{{ 'Help' | translate }}</span>
			</a>
		</li>
		<li *ngFor="let link of helpLinks.customHelpLinks" role="none">
			<a class="help-menu-item" [target]="link.uri.startsWith('mailto') ? undefined : '_blank'" rel="noopener noreferrer" [href]="link.uri" role="menuitem">
				<span class="text-label">{{link.label}}</span>
			</a>
		</li>
	</ul>
</ng-container>
