<div class="flex-row" [ngClass]="styles.dialogHeader">
	<span>{{ 'Media_Playlists_NewPlaylist' | translate }}</span>
	<button type="button" vbBtnSecondary (click)="hideDialog()">
		<span class="vb-icon vb-icon-close"></span>
	</button>
</div>
<form (submit)="createPlaylist()" [ngClass]="styles.dialogContent">
	<vb-ui-form-group>
		<label #uiLabel>{{ 'Media_Playlists_PlaylistName' | translate}}</label>
		<input type="text" class="form-control" name="name" id="name" [(ngModel)]="playlistName" #name="ngModel" uiControl vbRequired vbUiTextInput>
		<div *ngIf="name.invalid" role="alert" vbUiErrorMessage uiValidation>
			<div *ngIf="name.errors.required">
				{{ 'ThisFieldIsRequired' | translate }}
			</div>
		</div>
	</vb-ui-form-group>
	<div class="flex-row" *ngIf="!playlistType">
		<button type="button" [ngClass]="[
			styles.largeRounded,
			selectedPlaylistType == PlaylistType.Static ? styles.selected : '']" (click)="selectedPlaylistType = PlaylistType.Static">
			<span>{{ 'Media_Playlists_Static' | translate }}</span>
			<div>{{ 'Media_Playlists_StaticDesc' | translate }}</div>
		</button>
		<button type="button" [ngClass]="[
			styles.largeRounded,
			selectedPlaylistType == PlaylistType.Dynamic ? styles.selected : '']" (click)="selectedPlaylistType = PlaylistType.Dynamic">
			<span>{{ 'Media_Playlists_Dynamic' | translate }}</span>
			<div>{{ 'Media_Playlists_DynamicDesc' | translate }}</div>
		</button>
	</div>
	<div [ngClass]="styles.submitContainer">
		<button type="button" vbBtnSecondary (click)="hideDialog()">
			{{ 'Cancel' | translate }}
		</button>
		<button type="submit" vbBtnPrimary [disabled]="name.invalid">
			{{ 'Save' | translate }}
		</button>
	</div>
</form>
